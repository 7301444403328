import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { NotificationManager } from "react-notifications";

import MemberLevel from "./MemberLevel";
import UpdateUser from "./UpdateUser";
import Security from "./Security";
import KYC from "./KYC";

const MemberPage = observer(() => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, user_wallet, clear },
  } = useStores();

  const { mutate: onLogout } = useMutation(AuthApi.logout, {
    onSuccess: (res) => {
      clear();
    },
    onError: (error) => {
      clear();
      const errorMessage = error?.message ?? "Vui lòng thử lại!";
      NotificationManager.error(errorMessage);
    },
  });

  const getProfile = () => {
    queryClient.invalidateQueries(["get_profile"]);
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <div className="container  px-4 mx-auto my-10">
      <UpdateUser />
      <MemberLevel />
      <Security />
      <KYC />
    </div>
  );
});

export default MemberPage;
