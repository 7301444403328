import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import SelectBox from "_common/component/SelectBox";
import { useStores } from "_common/hooks";
import { useQuery } from "@tanstack/react-query";
import { GamePlayerApi } from "states/api/gamePlayer";
import classNames from "classnames";
import { GameApi } from "states/api/game";

const HistoryData = observer(({data, page}) => {
  const {
    authStore: { games },
  } = useStores();

 
  const listData = useMemo(() => {
    const result = [];
    for (let index = 0; index < 20; index+=1) {
      const element = {
        type: "open"
      };
      result.push(element);
    }

    return result;
  }, [data, page]);

  return (
    <div className="result-master">
      <div className="result-list clone">
        {listData?.map(item => (
          <div  className="item"/>
        ))}
      </div>
      <div className="result-list">
        {data?.map(item => (
          <div className={classNames("item", {
            [item?.result]: true
          })} />
        ))}
        </div>
      </div>
  );
});

export default HistoryData;
