import React, { useEffect, useState } from "react";
import { useStores } from "_common/hooks";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import PageLoading from "_common/component/PageLoading";
import { observer } from "mobx-react-lite";
import NotifyProvider from "_common/component/NotifyProvider";

import { useQuery } from "@tanstack/react-query";
import { AuthApi } from "states/api";

import MobileLayout from "./MobileLayout";
import DesktopLayout from "./DesktopLayout";

const BasicLayout = observer(() => {
  const {
    authStore: { logo, token, user, stocks, updateUser },
  } = useStores();
  const { isMobile } = useMediaQuery();

  useQuery(["get_profile"], AuthApi.getProfile, {
    staleTime: 300000,
    enabled: !!token,
    onSuccess: (res) => {
      updateUser({ user: res?.data });
    },
  });

  if (!logo) return <PageLoading />;
  if (!token) return <PageLoading />;
  if (!user?.id) return <PageLoading />;

  return (
     <NotifyProvider stocks={stocks}>
      {isMobile ? <MobileLayout /> : <DesktopLayout />}
    </NotifyProvider>
  );
});

export default BasicLayout;
