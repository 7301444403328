import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSmile,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";

import { Portal } from "react-portal";
import { formatNumber } from "_common/utils/formatValue";

export default function ModalWin({ data, onClose, visible }) {
  useEffect(() => {
    setTimeout(() => {
      onClose();
    }, 8000);
  }, []);
  return (
    <Portal>
      <div
        className="modal modal-win"
        style={{ display: visible ? "block" : "none" }}
      >
        <div className="modal-content">
          <span className="close" onClick={onClose}>
            ×
          </span>
          {data?.status === "win" ? (
            <div className="lightBox-content">
              <h2>Chúc mừng</h2>
              <h3>+{formatNumber(data?.total_win)}VND</h3>
            </div>
          ) : (
            <div className="lightBox-content lose">
              <h3>Thua cuộc</h3>
              <h3>-{formatNumber(data?.total_lose)}VND</h3>
            </div>
          )}
        </div>
      </div>
    </Portal>
  );
}
