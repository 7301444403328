import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { formatNumber } from "_common/utils/formatValue";
import { useMediaQuery } from "_common/component/MediaQueryProvider";

import { Portal } from "react-portal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { gameBetItemType } from "_common/constants/statusType";

const ModalBet = observer(({ open, onClose, onFinish, dataGame }) => {
  const { isMobile } = useMediaQuery();
  const [time, setTime] = useState(10);

  useEffect(() => {
    const interval = setInterval(() => {
      if (time > 0) {
        setTime((el) => el - 1);
      }
    }, 1000);

    if (time <= 0) {
      onClose();
    }

    return () => {
      clearInterval(interval);
    };
  }, [time]);

  if (!open) return <div />;

  return (
    <Portal>
      <div className="modal modal-confirm" style={{ opacity: open ? 1 : 0 }}>
        <div className="mask" onClick={() => onClose()} />
        <div className="modal-content !bg-[#2a405c] !border-0 !rounded-b-none !top-[0] !bottom-[0] !translate-x-[-50%] !translate-y-[0]">
          <div className="modal-body">
            <FontAwesomeIcon className="icon-success" icon={faCheckCircle} />
            <h1>Đặt lệnh thành công</h1>
            <ul>
              <li>Phiên giao dịch: {dataGame?.game_no}</li>
              <li>Mua/Bán: {gameBetItemType[dataGame?.type]}</li>
              <li>Số tiền giao dịch: {formatNumber(dataGame?.amount)} VND</li>
            </ul>
            <button
              type="button"
              className="btn-confirm"
              onClick={() => onFinish()}
            >
              Xác nhận ({time} Đóng sau vài giây)
            </button>
          </div>
        </div>
      </div>
    </Portal>
  );
});

export default ModalBet;
