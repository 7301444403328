import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import { useStores } from "_common/hooks";
import { formatBalance, formatNumber } from "_common/utils/formatValue";
import { observer } from "mobx-react-lite";

const HeaderDesktop = observer(({ is_open, setMenuOpen }) => {
  const {
    authStore: { display_balance, user_wallet, game_wallet, logo, user },
  } = useStores();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { isMobile } = useMediaQuery();

  return (
    <div className="flex items-center w-full py-2 border-b border-gray-300 dark:border-gray-800 boxHeader">
      {isMobile ? (
        <button
          aria-label="web"
          type="button"
          className="ml-2 block"
          onClick={() => setMenuOpen(!is_open)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="sbui-icon "
          >
            <line x1="3" y1="12" x2="21" y2="12" />
            <line x1="3" y1="6" x2="21" y2="6" />
            <line x1="3" y1="18" x2="21" y2="18" />
          </svg>
        </button>
      ) : (
        <img src={logo} alt="" />
      )}
      <div className="sbui-space-row sbui-space-x-6 px-4 ml-auto">
        {!isMobile && (
          <div className="sbui-btn-container">
            <div className="flex flex-col ">
              <span className="text-orange-400 text-xs">Người dùng</span>
              <span className="text-sm md:text-[16px] text-white ">
                {user?.username}
              </span>
            </div>
          </div>
        )}

        <div className="flex flex-col ">
          <span className="text-orange-400 text-xs">Số dư</span>
          <span className="text-sm md:text-[16px] text-white font-bold">
            {formatBalance(game_wallet?.amount, display_balance)} VND
          </span>
        </div>
      </div>
    </div>
  );
});

export default HeaderDesktop;
