import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useSearchParams } from "react-router-dom";

import deviceIcon from "assets/img/banner-mac-dinh.png";
import hanhtrinh from "assets/img/hanh-trinh.png";
import banner3 from "assets/img/trade-faster-img.png";
import security1 from "assets/img/security-img-1.png";
import security2 from "assets/img/security-img-2.png";
import security3 from "assets/img/security-img-3.png";
import security4 from "assets/img/security-img-4.png";
import security5 from "assets/img/anh-nen-trang.png";
import RegisterPage from "pages/Auth/Register";
import LoginPage from "pages/Auth/Login";
import { AuthApi } from "states/api";
import { useMutation } from "@tanstack/react-query";
import { NotificationManager } from "react-notifications";
import i18next from "i18next";

const HomeUnAuth = observer(() => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const type = params.get("type");

  const {
    authStore: { clear, authenticated, logo },
  } = useStores();

  const [type_open, setTypeOpen] = useState();

  const { mutate: onLogout } = useMutation(AuthApi.logout, {
    onSuccess: (res) => {
      clear();
    },
    onError: (error) => {
      clear();
      const errorMessage = error?.message ?? "Vui lòng thử lại!";
      NotificationManager.error(errorMessage);
    },
  });

  useEffect(() => {
    if (!type) return;
    if (!authenticated) {
      setTypeOpen(type);
    } else {
      navigate("/board/member");
    }
  }, [type, authenticated]);

  const handleClose = () => {
    setTypeOpen();
  };

  return (
    <div className="app-page login-page dark">
      <div className="app-header">
        <a href="https://lnworkjobvn.com"
            aria-label="web"
        >
          <img src={logo} alt="" />
        </a>
        <div />
        <div className="right-nav">
          {authenticated ? (
            <>
              <button
                type="button"
                onClick={() => navigate("/board/wallet")}
                className="sbui-btn sbui-btn-primary sbui-btn--large !rounded-lg px-6 !bg-fuchsia-500 sbui-btn--text-align-center font-bold "
              >
                <span>{i18next.t("my_wallet")}</span>
              </button>
              <button
                type="button"
                onClick={onLogout}
                className="sbui-btn sbui-btn-secondary sbui-btn--large !rounded-lg px-6 sbui-btn--text-align-center font-bold "
              >
                <span>{i18next.t("logout")}</span>
              </button>
            </>
          ) : (
            <>
              <button
                type="button"
                className="sbui-btn sbui-btn-primary sbui-btn--large !rounded-lg px-6 sbui-btn--text-align-center font-bold "
                onClick={() => setTypeOpen("register")}
              >
                <span>{i18next.t("register")}</span>
              </button>
              <button
                type="button"
                className="sbui-btn sbui-btn-secondary sbui-btn--large !rounded-lg px-6 sbui-btn--text-align-center font-bold "
                onClick={() => setTypeOpen("login")}
              >
                <span>{i18next.t("login")}</span>
              </button>
            </>
          )}
        </div>
      </div>
      <div className="container">
        <section className="intro">
          <div className="col1">
            <div className="content">
              <h2 className="text-4xl font-bold lg:text-4xl mb-8">
                Sàn giao dịch tài sản số hàng đầu
              </h2>
              <p className="text">
                SuccessTraderBitcoin là nền tảng dễ dàng và an toàn nhất để giao
                dịch tài sản số.
              </p>
              <button
                type="button"
                className="sbui-btn sbui-btn-primary sbui-btn--2xl !rounded-2xl mt-6 sbui-btn--text-align-center"
                onClick={() => setTypeOpen("register")}
              >
                <span>{i18next.t("start_text")}</span>
              </button>
            </div>
          </div>
          <div className="col2">
            <img src={deviceIcon} alt="" />
          </div>
        </section>
      </div>
      <section className="page1">
        <img src={logo} className="margin-center !mb-8 " alt="" />
        <h3 className=" text-4xl font-bold text-white lg:text-4xl mb-8 text-center">
          Trải nghiệm giao dịch mới
        </h3>
        <p className="text-gray-500 text-center !mb-8">
          Nền tảng của chúng tôi cung cấp các dịch vụ đẳng cấp thế giới cho cả
          nhà giao dịch mới lẫn nhà giao dịch chuyên nghiệp. Với các chỉ báo
          giao dịch theo thời gian thực, nhà giao dịch sẽ đưa ra quyết định tốt
          hơn và kiếm được nhiều tiền hơn trên SuccessTraderBitcoin.
        </p>
        <img src={hanhtrinh} alt="" className="margin-center" />
      </section>

      <section className="page3">
        <div className="container">
          <div className="flex justify-center gap-10">
            <div className="col1 text-center">
              <img src={banner3} alt="" />
            </div>
            <div className="col2">
              <h2 className="text-4xl font-bold lg:text-4xl mb-8">
                Nền tảng của chúng tôi hỗ trợ các loại tiền điện tử phổ biến
              </h2>
              <p className="text-gray-500 ">
                Bằng cách tích hợp tiền điện tử, SuccessTraderBitcoin có thể
                cung cấp trải nghiệm giao dịch nhanh chóng và đáng tin cậy trên
                toàn thế giới.
              </p>
              <button
                type="button"
                className="sbui-btn sbui-btn-primary sbui-btn--2xl !rounded-2xl mt-6 sbui-btn--text-align-center"
                onClick={() => setTypeOpen("register")}
              >
                <span>{i18next.t("start_text")}</span>
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="page4">
        <div className="container">
          <h2 className="text-4xl font-bold lg:text-4xl mb-8">
            Trải nghiệm giao dịch tuyệt vời
          </h2>
          <p className="text-gray-500 ">
            Từ ngày đầu tiên, chúng tôi đã thiết kế và xây dựng một sàn giao
            dịch tài sản kỹ thuật số thân thiện với người dùng cho cả những
            người mới và người có kinh nghiệm.
          </p>
          <div className="security-row px-4">
            <div className="security-col-lg-6 security-col-12">
              <div className="security-item">
                <div className="security-item-inner">
                  <img src={security1} alt="" className="security-item-img" />
                  <h3 className="security-item-title">
                    Các Chỉ báo kỹ thuật Chất lượng cao
                  </h3>
                  <p className="security-item-text">
                    Kiếm thu nhập thụ động và tăng khả năng giao dịch của bạn.
                  </p>
                </div>
              </div>
            </div>
            <div className="security-col-lg-6 security-col-12">
              <div className="security-item">
                <div className="security-item-inner">
                  <img src={security2} alt="" className="security-item-img" />
                  <h3 className="security-item-title">Nền tảng Bảo mật cao</h3>
                  <p className="security-item-text">
                    Đội ngũ bảo mật hàng đầu và quy trình quản lý rủi ro nghiêm
                    ngặt đảm bảo an toàn cho tài sản và thông tin cá nhân của
                    bạn.
                  </p>
                </div>
              </div>
            </div>
            <div className="security-col-lg-6 security-col-12">
              <div className="security-item">
                <div className="security-item-inner">
                  <img src={security4} alt="" className="security-item-img" />
                  <h3 className="security-item-title">
                    Kiếm thu nhập thụ động
                  </h3>
                  <p className="security-item-text">
                    Các nhà giao dịch cũng có thể trở thành một Thành viên VIP
                    để kiếm thu nhập thụ động mỗi khi giao dịch được thực hiện.
                  </p>
                </div>
              </div>
            </div>
            <div className="security-col-lg-6 security-col-12">
              <div className="security-item">
                <div className="security-item-inner">
                  <img src={security3} alt="" className="security-item-img" />
                  <h3 className="security-item-title">
                    Giải pháp Giao dịch Trọn vẹn
                  </h3>
                  <p className="security-item-text">
                    Chỉ số giá dựa trên dữ liệu đã xác minh từ các sàn giao dịch
                    tiền điện tử nổi tiếng.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="trade-now">
        <div className="container">
          <div className="trade-now-content">
            <img src={security5} alt="" className="trade-now-img" />
            <h2 className="trade-now-title smallTitle">
              Bắt đầu giao dịch, bắt đầu với SuccessTraderBitcoin
            </h2>
            <p className="trade-now-description">
              Bắt đầu Giao dịch và Kiếm Thu nhập Thụ động ngay hôm nay!
            </p>
            <button
              type="button"
              className="sbui-btn sbui-btn-primary sbui-btn--2xl !rounded-2xl mt-6 sbui-btn--text-align-center"
              onClick={() => setTypeOpen("register")}
            >
              <span>{i18next.t("start_text")}</span>
            </button>
          </div>
        </div>
      </section>
      <footer className="app-footer">
        <div className="container">
          <div className="block ">
            <img src={logo} alt="" />
            <p className="text-xs text-gray-400">
              © 2001 SuccessTraderBitcoin All Rights Reserved
            </p>
          </div>
          <div className="diver" />
          <div className="block ">
            <p className="text-xs text-gray-400">
              Cảnh báo rủi ro: Giao dịch và đầu tư vào các tùy chọn kỹ thuật số
              có mức độ rủi ro đáng kể và không phù hợp và / hoặc thích hợp cho
              tất cả khách hàng. Vui lòng đảm bảo rằng bạn cân nhắc cẩn thận các
              mục tiêu đầu tư, mức độ kinh nghiệm và khẩu vị rủi ro trước khi
              mua hoặc bán bất kỳ tài sản kỹ thuật số nào. Bạn nên nhận thức và
              hiểu đầy đủ tất cả các rủi ro liên quan đến giao dịch và đầu tư
              vào tài sản kỹ thuật số, bạn không nên đầu tư các khoản tiền mà
              bạn không thể để mất. Bạn được cấp các quyền hạn chế không độc
              quyền để sử dụng tài sản trí tuệ có trong trang web này cho mục
              đích sử dụng cá nhân, phi thương mại, không thể chuyển nhượng liên
              quan đến các dịch vụ được cung cấp trên trang web.
            </p>
          </div>
        </div>
      </footer>
      <LoginPage
        open={type_open}
        onClose={handleClose}
        setTypeOpen={setTypeOpen}
      />
      <RegisterPage
        open={type_open}
        onClose={handleClose}
        setTypeOpen={setTypeOpen}
      />
    </div>
  );
});

export default HomeUnAuth;
