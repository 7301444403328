import React, { useEffect, useState } from "react";

import classNames from "classnames";
import { useStores } from "_common/hooks";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { NotificationManager } from "react-notifications";
import { PaymentApi } from "states/api/payment";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatBalance, formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";
import { observer } from "mobx-react-lite";
import InputNumber from "_common/component/InputNumber";

import AddBankCard from "./AddBankCard";

const schema = yup
  .object({
    amount: yup.number().required("Bắt buộc điền"),
    otp: yup.string(),
  })
  .required();

const Withdraw = observer(() => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [method, setMethod] = useState("game");

  const {
    authStore: { user, general, user_wallet, game_wallet, display_balance },
  } = useStores();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { mutate: onSubmit, isLoading } = useMutation(
    (variables) =>
      PaymentApi.deposit({
        params: {
          ...variables,
          bank_method: method,
          token_id:
            method === "vnd" ? user_wallet?.tokenId : game_wallet?.tokenId,
          type: "withdraw",
        },
      }),
    {
      onSuccess: (res) => {
        reset();
        queryClient.invalidateQueries(["get_profile"]);
        queryClient.invalidateQueries(["payment", "list"]);
        NotificationManager.success(res?.msg || "Rút tiền thành công!");
      },
      onError: (error) => {
        const errorMessage = error?.message ?? "Vui lòng thử lại!";
        NotificationManager.error(errorMessage);
      },
    }
  );

  const onSave = (values) => {
    if (isLoading) return null;
    const amount = values?.amount;
    console.log(values);

    if (amount < Number(general?.min_withdraw || 0))
      return NotificationManager.error(
        `Vui lòng nhập số tiền lớn hơn ${formatNumber(
          general?.min_withdraw
        )}đVND`
      );
    if (amount > Number(general?.max_withdraw || 0))
      return NotificationManager.error(
        `Vui lòng nhập số tiền nhỏ hơn ${formatNumber(
          general?.max_withdraw
        )}đVND`
      );
    onSubmit({ ...values });
    return null;
  };

  return (
    <div id="withdraw" className="container profileContent">
      <div className="row">
        <div className="container px-4 mx-auto my-10 col-lg-7 col-sm-9 col-11">
          <div className="flex flex-1">
            <div className="sbui-card w-full h-full">
              <form
                className="sbui-card-content"
                onSubmit={handleSubmit(onSave)}
              >
                <div className="flex flex-col w-full gap-4">
                  <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
                    <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                      <div className="sbui-formlayout__label">
                        {i18next.t("bank_name")}
                      </div>
                    </div>
                    <div className="sbui-formlayout__content-container-horizontal">
                      <div className="sbui-select-container">
                        <select className="sbui-select sbui-select--medium">
                          <option value="970425">
                            {user?.bank_owner}/{user?.bank_number_display}/
                            {user?.bank_name}
                          </option>
                        </select>
                        <span className="sbui-select-chevron-container">
                          <svg
                            className="sbui-select-chevron"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col text-sm">
                    <span>{i18next.t("balance_type")}</span>
                    <div className="flex border border-[#24b47e] rounded-md">
                      <span className="sbui-btn-container sbui-btn--w-full">
                        <select className="sbui-select sbui-select--medium">
                          <option value="970425">VÍ ĐIỆN TỬ</option>
                        </select>
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
                      <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                        <div className="sbui-formlayout__label">
                          {i18next.t("Số dư khả dụng")}
                        </div>
                      </div>
                      <div className="sbui-formlayout__content-container-horizontal">
                        <div className="sbui-input-container">
                          <input
                            type="text"
                            readOnly
                            className="sbui-input sbui-input--medium"
                            value={formatNumber(game_wallet?.amount)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
                      <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                        <div className="sbui-formlayout__label">
                          {i18next.t("amount")}
                        </div>
                      </div>
                      <div className="sbui-formlayout__content-container-horizontal">
                        <div className="sbui-input-container">
                          <Controller
                            control={control}
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <InputNumber
                                min={0}
                                placeholder={`Tối thiểu ${formatNumber(
                                  general?.min_withdraw
                                )} VND`}
                                onBlur={onBlur}
                                onChangeText={onChange}
                                value={value}
                                className="sbui-input"
                              />
                            )}
                            name="amount"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <span className="sbui-btn-container sbui-btn--w-full">
                    <button
                      type="submit"
                      className="sbui-btn sbui-btn-primary sbui-btn--w-full sbui-btn-container--shadow sbui-btn--large sbui-btn--text-align-center"
                      disabled={!isValid}
                    >
                      <span>{i18next.t("confirm")}</span>
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>
          <div className="flex flex-1 mt-8">
            <AddBankCard />
          </div>
        </div>
      </div>
    </div>
  );
});

export default Withdraw;
