import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";
import classNames from "classnames";
import { get } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";

const CoinPrice = observer(({ coinData = [] }) => {
  const {authStore: {games}} = useStores();

  return (
    <>
      <div className="currency-header">
        <span className="name">Sản phẩm</span>
        <span className="price">Giá hiện tại</span>
        <span className="up-down">Tăng/Giảm</span>
      </div>
      <div className="view-content" id="slide-menu-pc">
        {coinData?.map((item, index) =>  (
            <div
              className="currency-item currency-item-show"
              key={`index-${index?.toString()}`}
            >
              <div className="tit">{item?.name}</div>
              <div
                className={classNames("view-item-data view-item-amplitude-new", {
                  "up": item?.rate > 0,
                  "down": item?.rate < 0,
                })}
              >
                {formatNumber(item?.price)}
              </div>
              <span
             className={classNames("view-item-data view-item-amplitude-new", {
              "up": item?.rate > 0,
              "down": item?.rate < 0,
            })}
              >
                <span data-sum="">{formatNumber(get(item, "rate", 0) * 100, "0,0.[0000]")}‱</span>
              </span>
            </div>
          )
        )}
      </div>
    </>
  );
});

export default CoinPrice;
