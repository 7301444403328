import classNames from "classnames";
import React, { useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHomeAlt,
  faGift,
  faHeadset,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";
import { useMutation } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { NotificationManager } from "react-notifications";
import i18next from "i18next";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import IconTrade from "assets/icons/IconTrade";
import IconWallet from "assets/icons/IconWallet";
import IconDeposit from "assets/icons/IconDeposit";
import IconWithdraw from "assets/icons/IconWithdraw";
import IconAccount from "assets/icons/IconAccount";
import IconLogin from "assets/icons/IconLogin";

export default function DesktopSideBar({ is_open }) {
  const {
    authStore: { clear, logo },
  } = useStores();
  const location = useLocation();
  const { isMobile } = useMediaQuery();
  const pathName = location?.pathname;

  const { mutate: onLogout } = useMutation(AuthApi.logout, {
    onSuccess: (res) => {
      clear();
    },
    onError: (error) => {
      clear();
      const errorMessage = error?.message ?? "Vui lòng thử lại!";
      NotificationManager.error(errorMessage);
    },
  });

  return (
    <div
      className={classNames(
        "remix-sidebar flex-col fixed z-10 w-24 h-screen max-h-full min-h-full bg-slate-900 lg:flex transition-all duration-500 delay-500",
        {
          hidden: !is_open,
        }
      )}
    >
      <div className="flex h-full">
        <ul className="flex flex-col flex-wrap gap-4 mx-auto mt-6">
          <li className="flex items-center justify-center">
            <Link
              to="/board/trade"
              className="flex flex-col justify-center gap-1 text-white hover:text-green-500 items-center"
            >
              <IconTrade />
              <span className="text-sm text-gray-500">
                {i18next.t("trade")}
              </span>
            </Link>
          </li>
          <li className="flex items-center justify-center">
            <Link
              to="/board/wallet"
              className="flex flex-col justify-center gap-1 text-white hover:text-green-500 items-center"
            >
              <IconWallet />
              <span className="text-sm text-gray-500">
                {i18next.t("wallet")}
              </span>
            </Link>
          </li>
          <li className="flex items-center justify-center">
            <Link
              to="/board/deposit"
              className="flex flex-col justify-center gap-1 text-white hover:text-green-500 items-center"
            >
              <IconDeposit />
              <span className="text-sm text-gray-500">
                {i18next.t("deposit")}
              </span>
            </Link>
          </li>

          <li className="flex items-center justify-center">
            <Link
              to="/board/withdraw"
              className="flex flex-col justify-center gap-1 text-white hover:text-green-500 items-center"
            >
              <IconWithdraw />
              <span className="text-sm text-gray-500">
                {i18next.t("withdraw")}
              </span>
            </Link>
          </li>
          <li className="flex items-center justify-center">
            <Link
              to="/board/member"
              className="flex flex-col justify-center gap-1 text-white hover:text-green-500 items-center"
            >
              <IconAccount />
              <span className="text-sm text-gray-500">
                {i18next.t("profile")}
              </span>
            </Link>
          </li>
          <li
            className={classNames("flex items-center justify-center  mb-2", {
              "mt-auto": !isMobile,
            })}
          >
            <span
              className="flex flex-col justify-center gap-1 cursor-pointer items-center"
              onClick={() => onLogout()}
            >
              <IconLogin />
              <span className="text-sm text-gray-500">
                {i18next.t("logout")}
              </span>
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
}
