import React, { useEffect, useMemo, useState } from "react";
import { get } from "lodash";
import { observer } from "mobx-react-lite";
import { formatBalance, formatNumber } from "_common/utils/formatValue";
import classNames from "classnames";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import { useStores } from "_common/hooks";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GameApi } from "states/api/game";
import { NotificationManager } from "react-notifications";
import { useNotify } from "_common/component/NotifyProvider";
import i18next from "i18next";
import { formatTimeSecond } from "_common/utils/datetimeFormat";

import MoneyInput from "./MoneyInput";
import MoneySelect from "./MoneySelect";
import CoinPrice from "./CoinPrice";
import ModalBet from "./ModalBet";
import HistoryData from "./HistoryData";

const gameTypeList = [
  {
    time: 30,
    profit: 50,
  },
  {
    time: 60,
    profit: 40,
  },
  {
    time: 90,
    profit: 30,
  },
  {
    time: 120,
    profit: 20,
  },
  {
    time: 150,
    profit: 10,
  },
];

const GameBoard = observer(({ game_id }) => {
  const queryClient = useQueryClient();
  const { isMobile } = useMediaQuery();
  const { setNotify, chartData } = useNotify();
  const {
    authStore: { user, display_balance, general, game_wallet, setLoading },
  } = useStores();

  const [openMoney, setOpenMoney] = useState(false);
  const [openConfirm, setConfirm] = useState(false);
  const [typeBet, setTypeBet] = useState();
  const [amount, setAmount] = useState(Number(get(general, "bet_default", 0)));

  const { mutate: onSubmit } = useMutation(
    (variables) =>
      GameApi.betGame({
        params: {
          ...variables,
        },
      }),
    {
      onSuccess: (res) => {
        setLoading(false);
        setTypeBet();
        setConfirm(false);
        // setAmount(Number(get(general, "amount", 0)));
        queryClient.invalidateQueries(["get_profile"]);
        queryClient.invalidateQueries(["game-player", "list"]);
        // NotificationManager.success(res?.msg || "Thao tác thành công");
        setNotify({
          type: "betGame",
          status: "new_bet",
        });
      },
      onError: (error) => {
        setLoading(false);
        const errorMessage = error?.message ?? "Vui lòng thử lại!";
        NotificationManager.error(errorMessage);
      },
    }
  );

  const handleSubmit = () => {
    if (!amount)
      return NotificationManager.error("Vui lòng chọn số lượng giao dịch");
    if (!game_id)
      return NotificationManager.error("Vui lòng chọn loại giao dịch");
    if (!typeBet)
      return NotificationManager.error("Vui lòng chọn loại giao dịch");
    setLoading(true);
    onSubmit({
      user_id: user?.id,
      game_no: chartData?.game_no,
      game_code: game_id,
      type: typeBet,
      amount,
      price: chartData?.price,
    });
    return null;
  };

  return (
    <div className="flex w-full px-4 ml-auto border-l lg:max-w-md dark:border-l-gray-900">
      <div className="flex flex-col flex-wrap flex-1 h-full">
        {!isMobile && <CoinPrice coinData={get(chartData, "data", [])} />}
        <div className="flex items-end">
          <div className="flex flex-col w-full gap-3 my-2">
            <div className=" board-time">
              <p>
                Phiên giao dịch: <span>{chartData?.game_no}</span>
              </p>
              <p>
                Vui lòng xác nhận giao dịch trong:{" "}
                <span>
                  {chartData?.time > 10
                    ? `${formatNumber(chartData?.time)}s`
                    : "Đóng phiên"}
                </span>
              </p>
            </div>
            {/* 
            <div className="grid grid-cols-5 gap-2">
              {gameTypeList.map((item) => (
                <span
                  key={item.time}
                  className="sbui-btn-container sbui-btn--w-full"
                >
                  <button
                    type="button"
                    onClick={() => setGameType(item.time)}
                    className={classNames(
                      "sbui-btn sbui-btn--w-full sbui-btn-container--shadow sbui-btn--tiny sbui-btn--text-align-center",
                      {
                        "sbui-btn-info": game_type === item.time,
                        "sbui-btn-default": game_type !== item.time,
                      }
                    )}
                  >
                    <span>
                      <div className="flex flex-col">
                        <span className="text-lg">{item.time}s</span>
                        <span className="font-extralight">{item.profit}%</span>
                      </div>
                    </span>
                  </button>
                </span>
              ))}
            </div> */}
            <div className="flex flex-col items-end flex-1">
              <div className="relative flex flex-col w-full">
                <MoneySelect
                  openMoney={openMoney}
                  setOpenMoney={setOpenMoney}
                  amount={amount}
                  setAmount={setAmount}
                />
                <MoneyInput
                  openMoney={openMoney}
                  setOpenMoney={setOpenMoney}
                  amount={amount}
                  setAmount={setAmount}
                />
              </div>
            </div>
            <div className="flex gap-2">
              <span className="sbui-btn-container sbui-btn--w-full">
                <button
                  type="button"
                  onClick={() => setTypeBet("up")}
                  className={classNames(
                    "sbui-btn sbui-btn-buy sbui-btn--w-full sbui-btn-container--shadow sbui-btn--xlarge  uppercase sbui-btn--text-align-center",
                    {
                      "!bg-yellow-400": typeBet === "up",
                    }
                  )}
                >
                  <span>{i18next.t("buy")}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="sbui-icon "
                  >
                    <polyline points="23 6 13.5 15.5 8.5 10.5 1 18" />
                    <polyline points="17 6 23 6 23 12" />
                  </svg>
                </button>
              </span>
              <span className="sbui-btn-container sbui-btn--w-full">
                <button
                  type="button"
                  onClick={() => setTypeBet("down")}
                  className={classNames(
                    "sbui-btn sbui-btn-buy sbui-btn--w-full sbui-btn-container--shadow sbui-btn--xlarge uppercase sbui-btn--text-align-center",
                    {
                      "!bg-yellow-400": typeBet === "down",
                      "!bg-red-700": typeBet !== "down",
                    }
                  )}
                >
                  <span>{i18next.t("sell")}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="sbui-icon "
                  >
                    <polyline points="23 18 13.5 8.5 8.5 13.5 1 6" />
                    <polyline points="17 18 23 18 23 12" />
                  </svg>
                </button>
              </span>
            </div>
            <button
              type="button"
              onClick={() => chartData?.time > 10 && setConfirm(true)}
              className="sbui-btn sbui-btn-sell sbui-btn--w-full sbui-btn-container--shadow sbui-btn--xlarge !bg-yellow-600 hover:!bg-red-600  uppercase sbui-btn--text-align-center"
            >
              <span>
                {chartData?.time > 10 ? i18next.t("confirm") : "Chờ kết quả"}
              </span>
            </button>
          </div>
        </div>
        <HistoryData />
      </div>
      {openConfirm && (
        <ModalBet
          onFinish={handleSubmit}
          open={openConfirm}
          onClose={() => setConfirm(false)}
          dataGame={{
            game_no: chartData?.game_no,
            amount,
            type: typeBet,
          }}
        />
      )}
    </div>
  );
});

export default GameBoard;
