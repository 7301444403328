import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import io from "socket.io-client";
import { socketUrl } from "_common/constants/common";
import { useQueryClient } from "@tanstack/react-query";
import { get } from "lodash";

let sock = null;

export const NotifyContext = createContext({});

export default function NotifyProvider({ stocks, children }) {
  const queryClient = useQueryClient();

  const [isLoading, setIsLoading] = useState(true);
  const [notify, setNotify] = useState(null);
  const [time_new_game, setTimeNewGame] = useState(null);
  const [chartData, setChartData] = useState({
    time: 60,
    game_no: null,
    currentTime: 0,
    startTime: 0,
    closeTime: 0,
    status: "start",
    open: 0,
    close: 0,
    high: 0,
    low: 0,
    price: 0,
    listPrice: [],
    ticks: [],
  });
  const [game_code, setGameActive] = useState();

  const onGetDataGame = (data) => {
    setChartData(data);
  };

  const onGameStart = (data) => {
    queryClient.invalidateQueries(["game", "list"]);
    setTimeNewGame(new Date().getTime());
  };

  const onJoinRoom = (room_id) => {
    sock.emit("join_room", {
      room_id,
    });
  };

  const onLeaveRoom = (room_id) => {
    sock.emit("leave_room", {
      room_id,
    });
  };

  const onConnectSocket = (e) => {
    console.log(e);
    setIsLoading(false);
    setGameActive(get(stocks[0], "code"));
  };

  const onNotify = (data) => {
    setNotify(data);
  };

  const betGame = useCallback((data) => {
    sock.emit("betGame", { ...data });
  }, []);

  useEffect(() => {
    sock = io(socketUrl, {
      transports: ["websocket"],
    });

    sock.on("open", onConnectSocket);
    sock.on("game", onGetDataGame);
    sock.on("gameStart", onGameStart);
    sock.on("push_notification", onNotify);
    return () => {
      sock.close();
    };
  }, []);

  useEffect(() => {
    if (!stocks?.length) return;
    if (!game_code) return;
    onJoinRoom(game_code);
  }, [stocks, game_code]);

  const handleChangeGame = (code) => {
    onLeaveRoom(game_code);
    setGameActive(code);
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      onJoinRoom(code);
    }, 1000);
  };

  const returnDefault = useMemo(() => (
      {
        isLoading,
      time_new_game,
      game_code,
      notify,
      chartData,
      setIsLoading,
      setGameActive: handleChangeGame,
      setNotify,
      betGame,
      onJoinRoom,
      onLeaveRoom
      }
   ), [betGame, notify, chartData, isLoading, game_code]);

  return (
    <NotifyContext.Provider value={returnDefault}>
      {children}
    </NotifyContext.Provider>
  );
}

export function useNotify() {
  return useContext(NotifyContext);
}
