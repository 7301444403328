import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import i18next from "i18next";
import { get } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { GamePlayerApi } from "states/api/gamePlayer";
import { gameBetItemType } from "_common/constants/statusType";
import { formatNumber } from "_common/utils/formatValue";

export default function HistoryData() {
  const [params, setParams] = useState({});

  const { data, refetch } = useQuery(
    ["game-player", "list"],
    () =>
      GamePlayerApi.getList({
        params: {
          page: 1,
          limit: 10,
          ...params,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <div className="flex self-start mt-2 overflow-hidden lg:mb-auto">
        <table className="w-full overflow-hidden border rounded-md shadow-sm table-fixed table-scroll dark:border-slate-800">
          <thead className="dark:bg-[#2A2A2A]">
            <tr className="text-sm">
              <td className="px-2 py-4 border-b border-slate-400 dark:border-slate-600 dark:text-slate-100">
                {i18next.t("time")}
              </td>
              <td className="px-2 py-4 border-b border-slate-400 dark:border-slate-600 dark:text-slate-100">
                <div className="flex flex-col">
                  <span>{i18next.t("game_no")}</span>
                </div>
              </td>
              <td className="px-2 py-4 border-b border-slate-400 dark:border-slate-600 dark:text-slate-100">
                <div className="flex flex-col items-center justify-center">
                  <span>{i18next.t("money_amount")}</span>
                </div>
              </td>
              <td className="px-2 py-4 border-b border-slate-400 dark:border-slate-600 dark:text-slate-100">
                <div className="flex flex-col items-center justify-center">
                  <span>{i18next.t("profit")}</span>
                </div>
              </td>
            </tr>
          </thead>
          <tbody className="dark:bg-[#2A2A2A] scrollbar-track-green-600 h-[calc(100vh-449px)]">
            {get(data, "data", [])?.map((item) => (
              <tr className="text-sm" key={item?.id}>
                <td className="px-2 py-4 border-b border-slate-200 dark:border-slate-600 text-orange-300 dark:opacity-50">
                  {moment(item?.created_at).format("DD/MM/YYYY HH:mm:ss")}
                </td>
                <td className="px-2 py-4 border-b border-slate-200 dark:border-slate-600 text-red-600">
                  <div className="flex flex-col">
                    <span className="dark:text-white text-uppercase">
                      {item?.game?.game_no}
                    </span>
                  </div>
                </td>
                <td className="px-2 py-4 border-b border-slate-200 dark:border-slate-600">
                  <div className="flex flex-col items-center justify-center">
                    <span className="dark:text-white">
                      {gameBetItemType[item?.type]}
                    </span>
                    <span className="text-red-600">
                      {formatNumber(item?.amount)}
                    </span>
                  </div>
                </td>
                <td className="px-2 py-4 border-b border-slate-200 dark:border-slate-600">
                  <div className="flex flex-col justify-center text-center">
                    {item?.status === "bet" ? (
                      <span className="text-yellow-700">
                        {i18next.t(item?.status)}
                      </span>
                    ) : (
                      <span
                        className={classNames({
                          "dark:text-white": !item?.amount_payback,
                          "text-green-600": Number(item?.amount_payback) > 0,
                        })}
                      >
                        {Number(item?.amount_payback) > 0 && "+"}
                        {formatNumber(item?.amount_payback)}
                      </span>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex flex-col w-full gap-2 my-2">
        <div className="flex items-center justify-between">
          <span className="sbui-btn-container">
            <button
              type="button"
              className="sbui-btn sbui-btn-secondary sbui-btn-container--shadow sbui-btn--tiny sbui-btn--text-align-center"
            >
              <span>{i18next.t("previous")}</span>
            </button>
          </span>
          <span className="sbui-btn-container">
            <button
              type="button"
              className="sbui-btn sbui-btn-secondary sbui-btn-container--shadow sbui-btn--tiny sbui-btn--text-align-center"
              disabled=""
            >
              <span>{i18next.t("next")}</span>
            </button>
          </span>
        </div>
      </div>
    </>
  );
}
