import React from "react";
import { useStores } from "_common/hooks";
import { get } from "lodash";

const moneyText = [
  {
    value: 20000,
    text: "+20K",
  },
  {
    value: 100000,
    text: "+100K",
  },
  {
    value: 200000,
    text: "+200K",
  },
  {
    value: 1000000,
    text: "+1M",
  },
  {
    value: 2000000,
    text: "+2M",
  },
  {
    value: 5000000,
    text: "+5M",
  },
  {
    value: 10000000,
    text: "+10M",
  },
  {
    value: 20000000,
    text: "+20M",
  },
  {
    value: 50000000,
    text: "+50M",
  },
  {
    value: 100000000,
    text: "+100M",
  },
  {
    value: 200000000,
    text: "+200M",
  },
  {
    value: 300000000,
    text: "+300M",
  },
];

export default function MoneySelect({
  setOpenMoney,
  openMoney,
  amount,
  setAmount,
}) {
  const {
    authStore: { game_wallet },
  } = useStores();

  if (!openMoney) return <div />;

  return (
    <div className="absolute left-0 right-0 top-auto grid grid-cols-3 gap-2 my-2 bottom-full">
      {moneyText.map((item) => (
        <span key={item.value} className="sbui-btn-container sbui-btn--w-full">
          <button
            type="button"
            onClick={() => {
              setAmount(item?.value);
              setOpenMoney(false);
            }}
            className="sbui-btn sbui-btn-secondary sbui-btn--w-full sbui-btn-container--shadow sbui-btn--large sbui-btn--text-align-center"
          >
            <span>{item?.text}</span>
          </button>
        </span>
      ))}

      <span className="sbui-btn-container sbui-btn--w-full">
        <button
          type="button"
          onClick={() => {
            setAmount(Number(get(game_wallet, "amount", 0)));
            setOpenMoney(false);
          }}
          className="sbui-btn sbui-btn-secondary sbui-btn--w-full sbui-btn-container--shadow sbui-btn--large sbui-btn--text-align-center"
        >
          <span>Tất cả</span>
        </button>
      </span>
    </div>
  );
}
