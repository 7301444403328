import React, { useEffect, useState } from "react";

import { useStores } from "_common/hooks";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { NotificationManager } from "react-notifications";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { BankApi } from "states/api/bank";
import { optionBank } from "_common/constants/bank";

import { Portal } from "react-portal";
import i18next from "i18next";

const schema = yup
  .object({
    bank_owner: yup.string().required("Bắt buộc điền"),
    bank_number: yup.string().required("Bắt buộc điền"),
    bank_code: yup.string().required("Bắt buộc điền"),
  })
  .required();

export default function ModalAddBank({ open, onClose }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user },
  } = useStores();

  const { mutate, isLoading } = useMutation(
    (variables) => BankApi.create({ params: variables }),
    {
      onSuccess: (res) => {
        onClose();
        queryClient.invalidateQueries(["get_profile"]);
        NotificationManager.success(res?.msg || "Thao tác thành công");
      },
      onError: (error) => {
        const errorMessage = error?.message ?? "Vui lòng thử lại!";
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSave = (values) => {
    if (isLoading) return;
    trigger();

    const bank = optionBank.find((obj) => obj.value === values?.bank_code);

    if (bank) {
      mutate({
        ...values,
        user_id: user?.id,
        bank_name: bank?.text,
      });
    } else {
      NotificationManager.error("Không tìm thấy ngân hàng");
    }
  };

  return (
    <Portal>
      <div className="modal" style={{ display: open ? "block" : "none" }}>
        <div className="mask" onClick={onClose} />
        <div className="modal-content sbui-modal-content">
          <form
            className="sbui-space-row sbui-space-x-5"
            style={{ alignItems: "flex-start", width: "100%" }}
            onSubmit={handleSubmit(onSave)}
          >
            <div
              className="sbui-space-col sbui-space-y-4"
              style={{ alignItems: "flex-start", width: "100%" }}
            >
              <h4 className="sbui-typography-title">
                {i18next.t("link_bank")}
              </h4>
              <div className="flex flex-col w-full gap-4">
                <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
                  <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                    <div className="sbui-formlayout__label">
                      {i18next.t("bank_name")}
                    </div>
                  </div>
                  <div className="sbui-formlayout__content-container-horizontal">
                    <div className="sbui-select-container">
                      <select
                        className="sbui-select sbui-select--medium"
                        {...register("bank_code")}
                      >
                        {optionBank.map((item) => (
                          <option key={item?.value} value={item?.value}>
                            {item.text} ({item.sortName})
                          </option>
                        ))}
                      </select>
                      <span className="sbui-select-chevron-container">
                        <svg
                          className="sbui-select-chevron"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
                    <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                      <div className="sbui-formlayout__label">
                        {i18next.t("bank_owner")}
                      </div>
                    </div>
                    <div className="sbui-formlayout__content-container-horizontal">
                      <div className="sbui-input-container">
                        <input
                          name="bank_account"
                          placeholder={i18next.t("bank_owner")}
                          type="text"
                          className="sbui-input sbui-input--medium"
                          {...register("bank_owner")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
                    <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                      <div className="sbui-formlayout__label">
                        {i18next.t("bank_number")}
                      </div>
                    </div>
                    <div className="sbui-formlayout__content-container-horizontal">
                      <div className="sbui-input-container">
                        <input
                          name="bank_number"
                          placeholder={i18next.t("bank_number")}
                          type="text"
                          className="sbui-input sbui-input--medium"
                          {...register("bank_number")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
                    <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                      <div className="sbui-formlayout__label">
                        {i18next.t("bank_branch")}
                      </div>
                    </div>
                    <div className="sbui-formlayout__content-container-horizontal">
                      <div className="sbui-input-container">
                        <input
                          name="bank_address"
                          placeholder={i18next.t("bank_branch")}
                          type="text"
                          className="sbui-input sbui-input--medium"
                          {...register("bank_branch")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!isValid && isSubmitted && (
                <p className="text-sm text-red-500">{i18next.t("note_12")}</p>
              )}

              <div
                className="sbui-space-row sbui-space-x-2"
                style={{ width: "100%", justifyContent: "flex-end" }}
              >
                <span className="sbui-btn-container">
                  <button
                    type="button"
                    onClick={() => onClose()}
                    className="sbui-btn sbui-btn-outline sbui-btn-container--shadow sbui-btn--tiny sbui-btn--text-align-center"
                  >
                    <span>{i18next.t("cancel")}</span>
                  </button>
                </span>
                <span className="sbui-btn-container">
                  <button
                    type="submit"
                    disabled={!isValid}
                    className="sbui-btn sbui-btn-primary sbui-btn-container--shadow sbui-btn--tiny sbui-btn--text-align-center"
                  >
                    <span>{i18next.t("confirm")}</span>
                  </button>
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Portal>
  );
}
