import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { formatNumber } from "_common/utils/formatValue";

function InputNumber({ onChangeText, value: valueInput, ...props }) {
  const inputRef = React.useRef(null);

  const [value, setValue] = useState();

  useEffect(() => {
    setValue(valueInput);
  }, [valueInput]);

  const handleChangeInput = (e) => {
    let val = e?.target?.value;
    val = val?.replace(/[^0-9]/g, "");
    setValue(val);
    onChangeText?.(val);
  };

  return (
    <>
      <input type="hidden" value={value} {...props} />
      <input
        ref={inputRef}
        type="text"
        inputMode="decimal"
        value={value ? formatNumber(value) : ""}
        onKeyUp={props?.onKeyUp}
        className={classNames("form-control shadow-none", props?.className)}
        placeholder={props?.placeholder}
        onChange={(e) => handleChangeInput(e)}
      />
    </>
  );
}

export default InputNumber;
