import React from "react";

export default function IconLogin() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="15"
      viewBox="0 0 16 15"
    >
      <g id="log-out" transform="translate(0 -1)">
        <path
          id="Path_29008"
          data-name="Path 29008"
          d="M6.5,5.5v-3a1,1,0,0,1,1-1h7a1,1,0,0,1,1,1v12a1,1,0,0,1-1,1h-7a1,1,0,0,1-1-1v-3"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1"
          className="stroke-color"
        />
        <line
          id="Line_1254"
          data-name="Line 1254"
          x1="11"
          transform="translate(0.5 8.5)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1"
          className="stroke-color"
        />
        <path
          id="Path_29009"
          data-name="Path 29009"
          d="M3.5,5.5l-3,3,3,3"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1"
          className="stroke-color"
        />
      </g>
    </svg>
  );
}
