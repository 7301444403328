import React, { useEffect, useState } from "react";
import { useStores } from "_common/hooks";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { NotificationManager } from "react-notifications";
import i18next from "i18next";
import KycForm from "./KycForm";

export default function KYC() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, general, clear },
  } = useStores();

  return (
    <div className="sbui-card my-10">
      <div className="sbui-card-content">
        <div className="flex border-b border-[#2a2a2a] -mx-8 px-10 md:px-5 -mt-8 py-8 mb-8">
          <div className="flex items-end gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="sbui-icon w-8 h-8 text-green-500"
            >
              <rect x="3" y="11" width="18" height="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
            <span className="text-lg">{i18next.t("verify")}</span>
          </div>
        </div>
        {user?.is_verify === 2 && (
          <p className="kyc-error">
            {user?.kyc_reason || i18next.t("note_23")}
          </p>
        )}
        {user?.identify_number && user?.is_verify !== 2 ? (
          <>
            {user?.is_verify === 0 && (
              <p className="kyc-warning">* {i18next.t("note_21")}</p>
            )}
            {user?.is_verify === 1 && (
              <p className="kyc-complete">* {i18next.t("note_22")}</p>
            )}
            <span className="block">{i18next.t("note_7")}</span>
            <span className="block">
              {i18next.t("note_9")}:{" "}
              <a
                className="text-green-600"
                target="_blank"
                href={general?.link_support}
                rel="noreferrer"
              >
                {general?.link_support}
              </a>
            </span>
          </>
        ) : (
          <KycForm />
        )}
      </div>
    </div>
  );
}
