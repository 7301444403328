import { useQuery, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import ModalBlock from "pages/Home/ModalBlock";
import ModalBonus from "pages/Home/ModalBonus";
import ModalWin from "pages/Trade/ModalWin";
import React, { useEffect, useState } from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import { GamePlayerApi } from "states/api/gamePlayer";
import Loading from "_common/component/Loading";
import { useNotify } from "_common/component/NotifyProvider";
import { useStores } from "_common/hooks";
import HeaderDesktop from "../Header/HeaderDesktop";

import DesktopSideBar from "../SideBar/DesktopSideBar";

const DesktopLayout = observer(() => {
  const queryClient = useQueryClient();
  const { time_new_game, notify, setNotify } = useNotify();
  const {
    authStore: { user, loading },
  } = useStores();
  const [openModalNotify, setModalNotify] = useState(false);
  const [openModalBlock, setModalBlock] = useState(false);
  const [dataWinInfo, setDataWinInfo] = useState({
    open: false,
    data: null,
  });
  const [dataNotice, setDataNotice] = useState();

  const { data, refetch } = useQuery(
    ["game", "info"],
    () =>
      GamePlayerApi.getInfo({
        params: {},
      }),
    {
      staleTime: 300000,
      enabled: false,
    }
  );

  useEffect(() => {
    if (data?.status === "win") {
      setDataWinInfo({
        open: true,
        status: "win",
        data,
      });
    } else if (data?.status === "lose") {
      setDataWinInfo({
        open: true,
        status: "lose",
        data,
      });
    }
  }, [data]);

  useEffect(() => {
    if (notify?.data?.data?.user_id === user?.id) {
      queryClient.invalidateQueries(["get_profile"]);
      setNotify();
    }
  }, [notify, user]);

  useEffect(() => {
    if (
      notify?.data?.type === "send_notice" &&
      notify?.data?.data?.user_id === user?.id
    ) {
      setDataNotice(notify?.data?.data);
      if (typeof poof === "function") {
        // eslint-disable-next-line no-undef
        poof();
      }
      queryClient.invalidateQueries(["game-player", "list"]);
      setModalNotify(true);
      setNotify();
    }
  }, [notify, user]);

  useEffect(() => {
    if (!user?.status) {
      setModalBlock(true);
    } else {
      setModalBlock(false);
    }
  }, [user?.status]);

  useEffect(() => {
    if (!time_new_game) return;
    queryClient.invalidateQueries(["get_profile"]);
    queryClient.invalidateQueries(["game-player", "list"]);
    refetch();
  }, [time_new_game]);

  return (
    <div className="tw-dark">
      <div className="desktop-layout font-['Roboto'] dark:text-gray-300 text-gray-900 w-full h-full">
        <div className="flex w-full h-full">
          <DesktopSideBar is_open />
          <div className="flex flex-col w-full h-screen max-h-full min-h-full lg:ml-24 transition-all ml-0 desktop-body">
            <HeaderDesktop />
            <div
              className={classNames("sbui-loading ", {
                "sbui-loading--active": loading,
              })}
            >
              <div className="loading-mask" />
              <div className="sbui-loading-content">
                <Outlet />
              </div>
              {loading && <Loading />}
            </div>
          </div>
        </div>
      </div>
      {openModalNotify && (
        <ModalBonus
          open={openModalNotify}
          onClose={() => setModalNotify(false)}
          data={dataNotice}
        />
      )}
      {openModalBlock && (
        <ModalBlock
          open={openModalBlock}
          onClose={() => setModalBlock(false)}
        />
      )}

      {dataWinInfo?.open && (
        <ModalWin
          visible={dataWinInfo?.open}
          data={{ ...dataWinInfo?.data, status: dataWinInfo?.status }}
          onClose={() => {
            setDataWinInfo({ open: false, data: null });
          }}
        />
      )}
    </div>
  );
});

export default DesktopLayout;
