import React, { useEffect, useState } from "react";
import { useStores } from "_common/hooks";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";

import Pagination from "_common/component/Pagination";
import { PaymentApi } from "states/api/payment";
import {
  paymentMethodText,
  paymentStatusText,
  paymentStatusColor,
  paymentTypeText,
  paymentTypeColor,
  paymentAddType,
} from "_common/constants/statusType";
import moment from "moment";
import { formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";

export default function ListHistoryBalance({type}) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, user_wallet, clear },
  } = useStores();

  const [activeTab, setActiveTab] = useState(type || "deposit");
  const [params, setParams] = useState();

  const { data, refetch } = useQuery(
    ["payment", "list", activeTab, params],
    () =>
      PaymentApi.getList({
        params: {
          page: 1,
          limit: 10,
          type: activeTab,
          ...params,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="boxCoin">
      {
        !type && (
<ul className="nav nav-tabs">
        <li
          role="presentation"
          className={classNames("item ", {
            active: activeTab === "deposit",
          })}
          onClick={() => setActiveTab("deposit")}
        >
          Nạp tiền
        </li>
        <li
          role="presentation"
          className={classNames("item ", {
            active: activeTab === "withdraw",
          })}
          onClick={() => setActiveTab("withdraw")}
        >
          Rút tiền
        </li>
      </ul>
        )
      }
      
      <div className="flex flex-col mt-2">
        <div className="inline-block px-4 py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden rounded-lg shadow">
            <table className="divide-y divide-gray-200 dark:divide-gray-700">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="p-2 text-xs font-medium tracking-wider text-left text-white uppercase md:px-6 md:py-3"
                  >
                    {i18next.t("Ngày đặt")}
                  </th>
                  <th
                    scope="col"
                    className="p-2 text-xs font-medium tracking-wider text-left text-white uppercase md:px-6 md:py-3"
                  >
                    {i18next.t("Số tiền")}
                  </th>
                  <th
                    scope="col"
                    className="p-2 text-xs font-medium tracking-wider text-left text-white uppercase md:px-6 md:py-3"
                  >
                    {i18next.t("Trạng thái")}
                  </th>
                </tr>
              </thead>
              <tbody className="text-sm ">
                {data?.data?.map((item) => (
                  <tr key={item?.id} className="">
                    <td className="p-2 text-white md:px-6 md:py-4 ">
                      <span className="text-xs sm:text-base">
                        {moment(item?.created_at).format("DD/MM/YYYY HH:mm")}
                      </span>
                    </td>
                    <td className="p-2 text-gray-700 md:px-6 md:py-4 ">
                      <span className="text-gray-900 dark:text-white">
                        {formatNumber(item?.final_total)} VND
                      </span>
                    </td>
                    <td className="p-2 text-gray-700 md:px-6 md:py-4 ">
                      <span
                        className={classNames(
                          "whitespace-nowrap text-xs sm:text-base",
                          {
                            [paymentStatusColor[item?.status]]: true,
                          }
                        )}
                      >
                        {i18next.t(item?.status)}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* {data?.total > 10 && (
        <div className="px-4">
          <Pagination data={data} />
        </div>
      )} */}
    </div>
  );
}
