import React, { useEffect, useState } from "react";
import { useStores } from "_common/hooks";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthApi, ProfileApi } from "states/api";
import { NotificationManager } from "react-notifications";
import i18next from "i18next";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup
  .object({
    new_password: yup
      .string()
      .min(6, "Vui lòng nhập mật khẩu tối thiểu 6 ký tự")
      .required("Vui lòng nhập mật khẩu mới"),
    confirmed: yup
      .string()
      .required("Vui lòng nhập lại mật khẩu mới")
      .oneOf([yup.ref("new_password")], "Mật khẩu không khớp"),
  })
  .required();

export default function Security() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, user_wallet, clear },
  } = useStores();

  const { mutate, isLoading } = useMutation(
    (variables) => ProfileApi.changePassword({ params: variables }),
    {
      onSuccess: (res) => {
        clear();
        NotificationManager.success(res?.msg || "Thao tác thành công");
      },
      onError: (error) => {
        const errorMessage = error?.message ?? "Vui lòng thử lại!";
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSave = () => {
    if (isLoading) return;
    trigger();
    const formValue = getValues();
    const isValid = schema.isValidSync(formValue);
    if (isValid) {
      mutate(formValue);
    }
  };

  return (
    <div className="sbui-card my-10">
      <form className="sbui-card-content" onSubmit={handleSubmit(onSave)}>
        <div className="flex border-b border-[#2a2a2a] -mx-8 px-10 md:px-5 -mt-8 py-8 mb-8">
          <div className="flex items-end gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="sbui-icon w-8 h-8 text-green-500"
            >
              <rect x="3" y="11" width="18" height="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
            <span className="text-lg">{i18next.t("security")}</span>
          </div>
        </div>
        <div className="flex flex-col items-center justify-between gap-4 mb-5 md:gap-10 md:flex-row">
          <div className="flex flex-col w-full">
            <div>
              <div className="sbui-formlayout sbui-formlayout--large sbui-formlayout--responsive">
                <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                  <div className="sbui-formlayout__label">
                    {i18next.t("password")}
                  </div>
                </div>
                <div className="sbui-formlayout__content-container-horizontal">
                  <div className="sbui-input-container">
                    <input
                      name="password"
                      placeholder={i18next.t("password")}
                      type="password"
                      className="sbui-input sbui-input--large"
                      defaultValue=""
                      {...register("new_password")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full">
            <div>
              <div className="sbui-formlayout sbui-formlayout--large sbui-formlayout--responsive">
                <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                  <div className="sbui-formlayout__label">
                    {i18next.t("confirmed_password")}
                  </div>
                </div>
                <div className="sbui-formlayout__content-container-horizontal">
                  <div className="sbui-input-container">
                    <input
                      name="confirm_password"
                      placeholder={i18next.t("confirmed_password")}
                      type="password"
                      className="sbui-input sbui-input--large"
                      defaultValue=""
                      {...register("confirmed")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-between gap-4 mb-5 md:gap-10 md:flex-row">
          <div className="flex flex-col w-full">
            <span className="sbui-btn-container sbui-btn--w-full">
              <button
                type="submit"
                className="sbui-btn sbui-btn-primary sbui-btn--w-full sbui-btn-container--shadow sbui-btn--large sbui-btn--text-align-center"
              >
                <span>{i18next.t("update_password")}</span>
              </button>
            </span>
          </div>
          <div className="flex flex-col w-full" />
        </div>
      </form>
    </div>
  );
}
