import React, { useEffect, useMemo, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useStores } from "_common/hooks";
import { SystemBankApi } from "states/api/systemBank";
import { handleCopyLink } from "_common/utils/helper";
import { formatNumber } from "_common/utils/formatValue";
import { Portal } from "react-portal";

export default function ModalBankDeposit({ onClose, open, amount, onFinish }) {
  const {
    authStore: { user, general },
  } = useStores();
  const queryClient = useQueryClient();

  const { data, refetch } = useQuery(
    ["bank_system", "detail"],
    () =>
      SystemBankApi.getDetail({
        params: {
          type: "system",
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <Portal>
      <div
        className="modal bank_modal"
        style={{ display: open ? "block" : "none" }}
      >
        <div className="modal-content">
          <span className="close" onClick={onClose}>
            ×
          </span>
          <h2> Thông tin nạp tiền</h2>

          <div className="d-flex flex-wrap">
            <div className="item_bank">
              <h3>
                Cách 1 <span>Chuyển khoản bằng mã QR</span>
              </h3>

              <img src={data?.qr_image_url} alt="" className="img-qr" />
            </div>
            <div className="item_bank">
              <h3>
                Cách 2 <span>Chuyển khoản thủ công</span>
              </h3>
              <div>
                <p className="title-bank">
                  Tên ngân hàng:{" "}
                  <span className="value">
                    {`${data?.bank_name} (${data?.bank_code})`}
                  </span>
                </p>
              </div>
              <div>
                <p className="name-account">
                  Chủ tài khoản :{" "}
                  <span className="value">{data?.bank_owner}</span>
                </p>
              </div>
              <div>
                <p className="sotk">
                  Số tài khoản :{" "}
                  <span className="value">{data?.bank_number}</span>
                </p>
                <span
                  className="copy"
                  data-text="Copied"
                  onClick={() => handleCopyLink(data?.bank_number)}
                >
                  Copy
                </span>
              </div>
              {/* <div>
              <p className="sotien">
                Số tiền USD:{" "}
                <span id="cf-amount" className="value">
                  {formatCurrency(amount)}
                </span>
              </p>
              <span
                className="copy"
                data-text="Copied"
                onClick={() => handleCopyLink(amount)}
              >
                Copy
              </span>
            </div> */}
              <div>
                <p className="sotien">
                  Số tiền VND:{" "}
                  <span id="cf-amount" className="value">
                    {formatNumber(amount)}VND
                  </span>
                </p>
                <span
                  className="copy"
                  data-text="Copied"
                  onClick={() => handleCopyLink(amount)}
                >
                  Copy
                </span>
              </div>
              <div className="note">
                <p className="note__">
                  Ghi chú Nội Dung Chuyển Khoản :{" "}
                  <span className="value">{user?.username}</span>
                </p>
                <span
                  className="copy"
                  data-text="Copied"
                  onClick={() => handleCopyLink(user?.phone)}
                >
                  Copy
                </span>
              </div>
            </div>

            <div id="saveChange" className="input-group">
              <button type="submit" className="btn-save" onClick={onFinish}>
                GỬI LỆNH
              </button>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
}
