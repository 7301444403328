import React, { useState } from "react";

import { useStores } from "_common/hooks";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import i18next from "i18next";
import ModalAddBank from "./ModalAddBank";

export default function AddBankCard() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user },
  } = useStores();
  const [openModal, setOpenModal] = useState(false);

  if (user?.bank_number) return <div />;

  return (
    <>
      <div className="sbui-card flex justify-center w-full h-full">
        <div className="sbui-card-content">
          <div className="flex flex-col gap-2">
            <span className="text-2xl">{i18next.t("note_10")}</span>
            <span className="sbui-btn-container">
              <button
                onClick={() => setOpenModal(true)}
                type="button"
                className="sbui-btn sbui-btn-primary sbui-btn-container--shadow sbui-btn--tiny sbui-btn--text-align-center"
              >
                <span>{i18next.t("note_11")}</span>
              </button>
            </span>
          </div>
        </div>
      </div>
      {openModal && (
        <ModalAddBank open={openModal} onClose={() => setOpenModal(false)} />
      )}
    </>
  );
}
