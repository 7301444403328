import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { useQueryClient } from "@tanstack/react-query";

import General from "./General";
import ListHistoryBalance from "./ListHistoryBalance";

const WalletPage = observer(() => {
  const queryClient = useQueryClient();
  const {
    authStore: { general },
  } = useStores();

  useEffect(() => {
    queryClient.invalidateQueries(["get_profile"]);
  }, []);

  return (
    <div className="row balance-page" id="balance-page">
      <div className="container  px-4 mx-auto my-10 col-lg-7 col-sm-9 col-11">
        <div className="sbui-space-col sbui-space-y-4 boxBalance">
          <div className="main-account mb-8">
            <h4 className="font-24  transaction-history-title">
              <b>Chi tiết</b>
            </h4>
            <General />
          </div>

          <h4 className="font-24  transaction-history-title">
            <b>Lịch sử giao dịch</b>
          </h4>
          <ListHistoryBalance />
        </div>
      </div>
    </div>
  );
});

export default WalletPage;
