import React from "react";

export default function IconDeposit() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.845"
      height="22.762"
      viewBox="0 0 25.845 22.762"
    >
      <g id="icon-deposit-green" transform="translate(0 0)">
        <g id="icon-deposit">
          <path
            id="Combined-Shape"
            d="M11.044,7.04c.051-.051.11-.084.162-.133A8.375,8.375,0,0,0,8.431,6.4a8.431,8.431,0,1,0,7.333,12.513l-4.73-4.731A5.047,5.047,0,0,1,11.044,7.04ZM25.351,9.425a1.677,1.677,0,0,0-2.374-.012l-1.9,1.9V2.181a1.686,1.686,0,0,0-3.372,0v9.134l-1.9-1.9A1.687,1.687,0,0,0,13.417,11.8l4.792,4.79a1.654,1.654,0,0,0,1.174.482,1.633,1.633,0,0,0,1.189-.482l4.792-4.79A1.687,1.687,0,0,0,25.351,9.425Z"
            transform="translate(0 -0.5)"
            fill="#fff"
            fillRule="evenodd"
          />
        </g>
      </g>
    </svg>
  );
}
