import React, { useEffect, useMemo, useState } from "react";
import InputNumber from "_common/component/InputNumber";

export default function MoneyInput({
  setOpenMoney,
  openMoney,
  amount,
  setAmount,
}) {
  const handleChangeInput = (event) => {
    setAmount(parseInt(event?.target?.value, 10));
  };

  return (
    <div className="sbui-space-row sbui-space-x-2 flex w-full gap-1">
      <div className="w-full amount-input">
        <div className="sbui-formlayout sbui-formlayout--large sbui-formlayout--responsive">
          <div
            className="sbui-formlayout__content-container-horizontal"
            style={{ width: "100%" }}
          >
            <div className="sbui-input-container">
              <InputNumber
                placeholder="0.0"
                value={amount}
                className="sbui-input sbui-input--large"
                onChangeText={handleChangeInput}
              />
              {/* <input
                placeholder="0.0"
                type="number"
                inputMode="decimal"
                className="sbui-input sbui-input--large"
                min={0}
                step="1"
                value={amount}
                onChange={handleChangeInput}
              /> */}
              <div className="sbui-space-row sbui-space-x-1 sbui-input-actions-container">
                <span className="mx-4 text-gray-400">VND</span>
                <span className="sbui-btn-container">
                  <button
                    type="button"
                    aria-label="web"
                    onClick={() => setOpenMoney(!openMoney)}
                    className="sbui-btn sbui-btn-primary sbui-btn-container--shadow sbui-btn--tiny sbui-btn--text-align-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="sbui-icon "
                    >
                      <rect x="3" y="3" width="7" height="7" />
                      <rect x="14" y="3" width="7" height="7" />
                      <rect x="14" y="14" width="7" height="7" />
                      <rect x="3" y="14" width="7" height="7" />
                    </svg>
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
