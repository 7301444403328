import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

function Pagination({ data }) {
  const ref = useRef(null);

  return (
    <div className="py-3 flex items-center justify-between">
      <div className="flex-1 flex justify-between lg:hidden">
        <button
          type="button"
          className="relative inline-flex items-center dark:text-white dark:border-gray-700 px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-900 bg-white dark:bg-gray-800 hover:bg-gray-50"
          disabled=""
        >
          Trước
        </button>
        <button
          type="button"
          className="relative inline-flex items-center dark:text-white dark:border-gray-700 px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-900 bg-white dark:bg-gray-800 hover:bg-gray-50"
          disabled=""
        >
          Tiếp theo
        </button>
      </div>
      <div className="hidden flex-1 lg:flex items-center justify-between">
        <div className="flex items-center gap-x-2">
          <select className="py-1 block w-full rounded-md dark:bg-gray-800 dark:border-gray-700 border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
            <option value="10">Show 10</option>
            <option value="20">Show 20</option>
            <option value="30">Show 30</option>
            <option value="40">Show 40</option>
            <option value="50">Show 50</option>
          </select>
        </div>
        <div className="flex">
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <button
              type="button"
              className="relative inline-flex items-center dark:text-white dark:border-gray-700 px-2 py-2 border border-gray-300 bg-white dark:bg-gray-800 text-sm font-medium text-gray-900 hover:bg-gray-50 rounded-l-md"
              disabled=""
            >
              <span className="sr-only">First</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="sbui-icon h-4 w-4"
                aria-hidden="true"
              >
                <polyline points="11 17 6 12 11 7" />
                <polyline points="18 17 13 12 18 7" />
              </svg>
            </button>
            <button
              type="button"
              className="relative inline-flex items-center dark:text-white dark:border-gray-700 px-2 py-2 border border-gray-300 bg-white dark:bg-gray-800 text-sm font-medium text-gray-900 hover:bg-gray-50"
              disabled=""
            >
              <span className="sr-only">Previous</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="sbui-icon h-4 w-4"
                aria-hidden="true"
              >
                <polyline points="15 18 9 12 15 6" />
              </svg>
            </button>
            <button
              type="button"
              className="relative inline-flex items-center dark:text-white dark:border-gray-700 px-2 py-2 border border-gray-300 bg-white dark:bg-gray-800 text-sm font-medium text-gray-900 hover:bg-gray-50"
              disabled=""
            >
              <span className="sr-only">Next</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="sbui-icon h-4 w-4"
                aria-hidden="true"
              >
                <polyline points="9 18 15 12 9 6" />
              </svg>
            </button>
            <button
              type="button"
              className="relative inline-flex items-center dark:text-white dark:border-gray-700 px-2 py-2 border border-gray-300 bg-white dark:bg-gray-800 text-sm font-medium text-gray-900 hover:bg-gray-50 rounded-r-md"
              disabled=""
            >
              <span className="sr-only">Last</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="sbui-icon h-4 w-4"
                aria-hidden="true"
              >
                <polyline points="13 17 18 12 13 7" />
                <polyline points="6 17 11 12 6 7" />
              </svg>
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Pagination;
