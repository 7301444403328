import i18next from "i18next";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";

// redux
import classNames from "classnames";

import RegisterForm from "./RegisterForm";

function RegisterPage({ open, onClose, setTypeOpen }) {
  const navigate = useNavigate();
  const {
    authStore: { logo },
  } = useStores();

  return (
    <>
      {open === "register" && <div className="mask" onClick={onClose} />}
      <div
        className={classNames("offcanvas offcanvas-end", {
          show: open === "register",
        })}
      >
        <div className="sbui-sidepanel--medium">
          <div className="sbui-sidepanel">
            <div
              className="sbui-space-col sbui-space-y-6"
              style={{ height: "100%" }}
            >
              <div className="sbui-sidepanel-header">
                <div className="sbui-space-row sbui-space-x-3">
                  <img src={logo} alt="" className="logo-login" />
                  <div className="sbui-sidepanel-close-container">
                    <span className="sbui-btn-container">
                      <button
                        type="button"
                        aria-label="web"
                        className="sbui-btn sbui-btn-text sbui-btn--tiny sbui-btn--text-align-center"
                        onClick={onClose}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="sbui-icon "
                        >
                          <line x1="18" y1="6" x2="6" y2="18" />
                          <line x1="6" y1="6" x2="18" y2="18" />
                        </svg>
                      </button>
                    </span>
                  </div>
                </div>
              </div>
              <div className="sbui-sidepanel-content">
                <div className="flex flex-col h-full">
                  <div className="sbui-auth">
                    <div className="sbui-space-col sbui-space-y-8 sbui-auth-label">
                      <RegisterForm setTypeOpen={setTypeOpen} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RegisterPage;
