import { get } from "lodash";
import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";

export class AuthStore {
  token = "";

  user = null;

  loading = false;

  theme_light = false;

  open_music = true;

  display_balance = true;

  device = "";

  avatars = [];

  stocks = [];
  
  games = [];

  user_wallet = {};

  game_wallet = {};

  general = {};

  logo = "";

  language = "vi";

  trading_view = "";

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "AuthStore",
      properties: [
        "token",
        "user",
        "device",
        "theme_light",
        "open_music",
        "display_balance",
        "language",
      ],
      storage: window.localStorage,
    });
  }

  get authenticated() {
    // TODO: This logic can be changed on demand
    return !!this.token;
  }

  clear = () => {
    this.token = "";
    this.user = null;
  };

  setInitData = (data) => {
    this.device = data?.device_code;
    this.trading_view = data?.trading_view;
    this.banners = data?.banners || [];
    this.games = data?.stocks || [];
    this.stocks = data?.stocks || [];
    this.avatars = data?.avatars || [];
    const general = data?.general || [];

    if (Array.isArray(general)) {
      const logoData = general?.find((obj) => obj?.name === "logo_dark");
      if (logoData) {
        this.logo = logoData?.image_url;
      }
    }

    const newsValue = {};

    general.forEach((item, index) => {
      newsValue[item.name] = item.value;
      if (item.value === "0") {
        newsValue[item.name] = false;
      }
    });

    this.general = newsValue;
  };

  updateToken = (token) => {
    this.token = token || this.token;
  };

  setLoading = (open) => {
    this.loading = open;
  };

  setThemeLight = (open) => {
    this.theme_light = open;
    window.location.reload();
  };

  setOpenMusic = (open) => {
    this.open_music = open;
    window.location.reload();
  };

  setDisplayBalance = (open) => {
    this.display_balance = open;
    window.location.reload();
  };

  setLanguage = (value) => {
    this.language = value;
    window.location.reload();
  };

  updateUser = (data) => {
    const tokens = get(data.user, "tokens", []) || [];
    this.user = data.user;
    this.token = data?.token || this.token;
    this.user_wallet = tokens?.find((obj) => obj.symbol === "vnd");
    this.game_wallet = tokens?.find((obj) => obj.symbol === "game");
  };
}
