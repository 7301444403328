import React from "react";
import { Routes, Route } from "react-router-dom";

import BasicLayout from "_common/component/Layout/BasicLayout";
import UnAuthLayout from "_common/component/Layout/UnAuthLayout";
import PageNotfound from "_common/component/PageNotfound";

// auth
import LoginPage from "pages/Auth/Login";
import RegisterPage from "pages/Auth/Register";

import { MiddlewareRouter } from "routers/MiddlewareRouter";
import CheckAuth from "routers/CheckAuth";
import MemberPage from "pages/Member";

import HomeUnAuth from "pages/HomeUnAuth";
import WalletPage from "pages/Wallet";
import FundPage from "pages/Fund";
import ReportPage from "pages/Report";
import TradePage from "pages/Trade";
import Withdraw from "pages/Wallet/Withdraw";
import Deposit from "pages/Wallet/Deposit";
import CheckAuthHome from "./CheckAuthHome";

export function Routers() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <CheckAuthHome>
            <UnAuthLayout />
          </CheckAuthHome>
        }
      >
        <Route index element={<HomeUnAuth />} />
        <Route path="home" element={<HomeUnAuth />} />
      </Route>
      <Route
        path="/board"
        element={
          <MiddlewareRouter>
            <BasicLayout />
          </MiddlewareRouter>
        }
      >
        <Route index element={<TradePage />} />
        <Route path="home" element={<TradePage />} />
        <Route path="member">
          <Route index element={<MemberPage />} />
        </Route>

        <Route path="wallet">
          <Route index element={<WalletPage />} />
        </Route>
        <Route path="withdraw">
          <Route index element={<Withdraw />} />
        </Route>
        <Route path="deposit">
          <Route index element={<Deposit />} />
        </Route>
        <Route path="fund">
          <Route index element={<FundPage />} />
        </Route>
        <Route path="report">
          <Route index element={<ReportPage />} />
        </Route>
        <Route path="trade">
          <Route index element={<TradePage />} />
          <Route path="history" element={<TradePage />} />
        </Route>
      </Route>
      <Route
        path="auth"
        element={
          <CheckAuth>
            <UnAuthLayout />
          </CheckAuth>
        }
      >
        <Route path="login" element={<LoginPage />} />
        <Route path="register" element={<RegisterPage />} />
      </Route>
      <Route path="*" element={<PageNotfound />} />
    </Routes>
  );
}
