export const paymentStatusText = {
  pending: "Đang xử lý",
  complete: "Hoàn thành",
  error: "Thất bại",
  reject: "Thất bại",
};

export const paymentTypeText = {
  withdraw: "Rút",
  deposit: "Nạp",
  refund: "Hoàn tiền",
};

export const paymentTypeColor = {
  withdraw: "text-red-600",
  deposit: "text-yellow-700",
  refund: "text-yellow-700",
  system: "text-yellow-700",
  commission: "text-yellow-700",
  add_money: "text-yellow-700",
  sub_money: "text-red-600",
};

export const paymentMethodText = {
  vnd: "TRC20",
  game: "ERC20",
};

export const paymentStatusColor = {
  pending: "text-yellow-700",
  complete: "text-green-600",
  error: "text-red-600",
};

export const paymentAddType = {
  withdraw: "-",
  deposit: "+",
  add_money: "+",
  sub_money: "-",
  commission: "+",
  bonus: "+",
};

export const gameStatusText = {
  betGame: "Đang cược",
  win: "Đã trúng thưởng",
  loss: "Chưa trúng thưởng",
};

export const gameTypeText = {
  bet1: "Quần áo",
  bet2: "Mũ",
  bet3: "Trang sức",
  bet4: "Đồng hồ",
  bet5: "Giày dép",
};

export const gameBetItemType = {
  up: "Mua",
  down: "Bán",
};

export const gameLevelText = {
  level_1: "DIOR",
  level_2: "GUCCI",
  level_3: "CHANEL",
};

export const positionKeyText = {
  agency: "Đại lý",
  user: "Thành viên",
  admin: "Quản trị viên",
  super: "Quản trị viên",
};
