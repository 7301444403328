import React from "react";

export default function IconWithdraw() {
  return (
    <svg
      id="icon-withdraw-red"
      xmlns="http://www.w3.org/2000/svg"
      width="23.178"
      height="21.884"
      viewBox="0 0 23.178 21.884"
    >
      <g id="icon-withdraw">
        <path
          id="Combined-Shape"
          d="M12.5,13.8V10.651a4.624,4.624,0,0,1-4.392-4.37c-.1,0-.192-.028-.292-.028a7.816,7.816,0,1,0,6.857,11.49A4.677,4.677,0,0,1,12.5,13.8Zm6.252-8.468L20.516,7.1a1.563,1.563,0,0,0,2.21-2.21L18.285.447a1.566,1.566,0,0,0-2.191,0L11.653,4.889a1.563,1.563,0,0,0,2.21,2.21l1.763-1.763V13.8a1.563,1.563,0,0,0,3.126,0Z"
          fill="#ffff"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}
