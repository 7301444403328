import React, { useState, useEffect, useCallback, useMemo } from "react";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useStores } from "_common/hooks";
import ApexCharts from "react-apexcharts";
import { useNotify } from "_common/component/NotifyProvider";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import { formatNumber } from "_common/utils/formatValue";
import { get } from "lodash";

const min = new Date(moment().subtract(1, "minutes")).getTime();
const max = new Date(moment().add(60, "seconds")).getTime();
const range = max - min;

const options = {
  chart: {
    id: "chartLine",
    type: "area",
    height: 350,
    animations: {
      enabled: false,
    },
    toolbar: {
      show: true,
      tools: {
        download: false,
        selection: false,
        zoom: false,
        zoomin: false,
        zoomout: false,
        pan: true,
        reset: false,
      },
      autoSelected: "pan",
    },
    zoom: {
      enabled: true,
      type: "x",
      autoScaleYaxis: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      endingShape: "rounded",
      // columnWidth: "40%",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    curve: "straight",
    colors: ["#ffffff"],
    width: 2,
  },
  markers: {
    size: 0,
  },
  animations: {
    enabled: true,
    easing: "linear",
    speed: 800,
    animateGradually: {
      enabled: true,
      delay: 150,
    },
    dynamicAnimation: {
      enabled: true,
      speed: 350,
    },
  },
  xaxis: {
    type: "datetime",
    min: new Date(moment().subtract(4, "minutes")).getTime(),
    max: new Date(moment().add(60, "seconds")).getTime(),
    range,
    tickAmount: 16,
    labels: {
      format: "HH:mm:ss",
      style: {
        colors: "#fff",
        fontSize: "12px",
      },
    },
  },
  yaxis: {
    tickAmount: 6,
    align: "right",
    labels: {
      align: "right",
      style: {
        colors: "#fff",
        fontSize: "12px",
      },
      formatter: (val, index) => {
        if (val < 100) {
          return formatNumber(val, "0,0.000");
        }

        return formatNumber(Math.floor(val));
      },
    },
  },
  grid: {
    show: true,
    borderColor: "rgba(102, 175, 218, 0.3)",
    strokeDashArray: 0,
    position: "back",
    clipMarkers: true,
    xaxis: {
      lines: {
        show: true,
        offsetX: "0.5",
        offsetY: "0.5",
      },
    },
    yaxis: {
      lines: {
        show: true,
        offsetX: "0.5",
        offsetY: "0.5",
      },
    },
    row: {
      colors: undefined,
      opacity: 0.5,
    },
    column: {
      colors: undefined,
      opacity: 0.5,
    },
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  fill: {
    opacity: 1,
    type: "gradient",
    colors: "#0086b3",
    gradient: {
      colorStops: [
        [
          {
            offset: 0,
            color: "#0086b3",
            opacity: 1,
          },
          {
            offset: 100,
            color: "#0086b3",
            opacity: 0.1,
          },
        ],
      ],
    },
  },
  legend: {
    show: false,
  },
  annotations: {
    xaxis: [
      {
        x: new Date(moment()).getTime(),
        borderColor: "#c2c2c2",
        fillColor: "#c2c2c2",
        opacity: 1,
        yAxisIndex: 0,
        width: "500%",
        offsetX: -11,
        strokeDashArray: 0,
        label: {
          show: true,
          text: "Giải quyết hiện tại 1799.2004",
          position: "bottom",
          orientation: "horizontal",
          offsetY: -10,
          borderWidth: 0,
          style: {
            color: "#fff",
            background: "#2877a4",
          },
        },
      },
      {
        x: new Date(moment().add(60, "seconds")).getTime(),
        x2: new Date(moment().add(75, "seconds")).getTime(),
        borderColor: "#999",
        fillColor: "#ffffff",
        opacity: 0.2,
        yAxisIndex: 0,
        borderWidth: 0,
        strokeDashArray: 0,
        label: {
          show: true,
          text: "Kết thúc mua hàng",
          position: "top",
          textAnchor: "end",
          orientation: "horizontal",
          offsetY: 10,
          offsetX: 10,
          borderWidth: 0,
          style: {
            color: "#fff",
            background: "#000",
          },
        },
      },
      {
        x: new Date(moment().add(75, "seconds")).getTime(),
        borderColor: "#999",
        yAxisIndex: 0,
        width: 10,
        strokeDashArray: 0,
        label: {
          show: true,
          text: "Đếm ngược. 33 Chia ra",
          position: "top",
          textAnchor: "start",
          orientation: "horizontal",
          offsetY: 10,
          offsetX: -10,
          borderWidth: 0,
          style: {
            color: "#fff",
            background: "#000",
          },
        },
      },
    ],
    yaxis: [
      {
        y: 1790,
        borderColor: "#999",
        width: 10,
        strokeDashArray: 0,
        label: {
          show: true,
          text: "Support",
          style: {
            color: "#fff",
            background: "#50A4C7",
          },
        },
      },
    ],
  },
  tooltip: {
    enabled: false,
  },
};

const ChartLine = observer(() => {
  const { authStore } = useStores();
  const { chartData } = useNotify();
  const { isMobile } = useMediaQuery();

  const [optionsData, setOptionData] = useState({
    ...options,
    yaxis: {
      ...options.yaxis,
      tickAmount: 5,
    },
  });
  const [dataChart, setDataChart] = useState([
    {
      data: [],
    },
  ]);

  const addNewData = (data) => {
    if (!data?.price) return;
    setDataChart([
      {
        data: get(data, "listPrice", []),
      },
    ]);
  };

  const handlePoint = useCallback(() => {
    const cloneData = {
      ...optionsData,
      xaxis: {
        ...optionsData.xaxis,
        min: new Date(moment().subtract(4, "minutes")).getTime(),
        max: new Date(moment().add(60, "seconds")).getTime(),
        range,
      },
      yaxis: {
        ...options.yaxis,
        min: get(chartData, "min", 0) - 10,
        max: get(chartData, "max", 0) + 10,
      },
      annotations: {
        ...optionsData.annotations,
        xaxis: [
          {
            x: chartData?.closeTime,
            borderColor: "#999",
            yAxisIndex: 0,
            width: 10,
            strokeDashArray: 0,
            label: {
              show: true,
              text: `Tỉ giá hối đoái ${formatNumber(chartData?.close)}`,
              position: "bottom",
              orientation: "horizontal",
              offsetY: -10,
              borderWidth: 0,
              style: {
                color: "#ffffff",
                background: "#0099ff",
              },
            },
          },
          {
            x: get(chartData, "closeTime", 0) + 52000,
            x2: get(chartData, "closeTime", 0) + 62000,
            borderColor: "#ffffcc",
            fillColor: "#ffffcc",
            opacity: 0.3,
            yAxisIndex: 0,
            width: 10,
            strokeDashArray: 0,
            label: {
              show: true,
              text: "Tạm ngừng giao dịch",
              position: "top",
              textAnchor: "end",
              orientation: "horizontal",
              offsetY: 10,
              offsetX: 10,
              borderWidth: 0,
              style: {
                color: "#ffff00",
                background: "rgba(153, 153, 0, 0.4)",
              },
            },
          },
          {
            x: get(chartData, "closeTime", 0) + 62000,
            borderColor: "#ffffcc",
            yAxisIndex: 0,
            width: 10,
            strokeDashArray: 0,
            label: {
              show: true,
              text: `Đếm ngược. ${`0${chartData?.time}`.substr(-2)} Chia ra`,
              position: "top",
              textAnchor: "start",
              orientation: "horizontal",
              offsetY: 10,
              offsetX: -10,
              borderWidth: 0,
              style: {
                color: "#ffff00",
                background: "rgba(153, 153, 0, 0.4)",
              },
            },
          },
        ],
        yaxis: [
          {
            y: Number(chartData?.price),
            borderColor: "#ffffff",
            strokeDashArray: 4,
            label: {
              show: true,
              text: `${formatNumber(chartData?.price)}`,
              offsetX: -10,
              offsetY: 5,
              style: {
                color: "#fff",
                background: "#207497",
                fontSize: "14px",
              },
            },
          },
        ],
      },
    };

    setOptionData(cloneData);
  }, [chartData, optionsData]);

  useEffect(() => {
    if (!chartData) return;
    handlePoint(chartData);
    addNewData(chartData);
  }, [chartData]);

  return (
    <ApexCharts
      id="chartLine"
      options={optionsData}
      series={dataChart}
      type="area"
      height={isMobile ? window.innerHeight / 2 : window.innerHeight / 1.5}
    />
  );
});

export default ChartLine;
