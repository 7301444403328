import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

function SelectItem({ children, isSelect, isDisable, onChange }) {
  const ref = useRef(null);

  return (
    <li
      id="headlessui-listbox-option-14"
      role="presentation"
      onClick={() => !isDisable && onChange()}
    >
      <div
        className={classNames("sbui-listbox-option", {
          "sbui-listbox-option--disabled": isDisable,
        })}
      >
        <div className="sbui-listbox-option__inner">
          <span>
            <span>{children}</span>
          </span>
        </div>
        {isSelect && (
          <span className="sbui-listbox-option__check">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="sbui-icon sbui-listbox-option__check__icon"
              aria-hidden="true"
            >
              <polyline points="20 6 9 17 4 12" />
            </svg>
          </span>
        )}
      </div>
    </li>
  );
}

export default SelectItem;
