import React, { useEffect, useMemo, useState } from "react";

import { useStores } from "_common/hooks";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { NotificationManager } from "react-notifications";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { BankApi } from "states/api/bank";

import { Portal } from "react-portal";
import { formatBalance, formatNumber } from "_common/utils/formatValue";
import SelectBox from "_common/component/SelectBox";
import { UserFundApi } from "states/api/userFund";
import i18next from "i18next";

const schema = yup
  .object({
    amount: yup
      .number()
      .min(0, "Vui lòng nhập số lớn hơn 0")
      .required("Bắt buộc điền"),
  })
  .required();

export default function ModalAddFund({ listFunds, open, type, onClose }) {
  const queryClient = useQueryClient();
  const {
    authStore: { user, game_wallet, general, display_balance },
  } = useStores();

  const { mutate, isLoading } = useMutation(
    (variables) => UserFundApi.create({ params: variables }),
    {
      onSuccess: (res) => {
        onClose();
        queryClient.invalidateQueries(["get_profile"]);
        NotificationManager.success(res?.msg || "Thao tác thành công");
      },
      onError: (error) => {
        const errorMessage = error?.message ?? "Vui lòng thử lại!";
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const defaultValueFund = useMemo(
    () => listFunds?.find((item) => item?.level === user?.level),
    [listFunds, user]
  );

  const onSave = (values) => {
    if (isLoading) return null;
    trigger();
    const amount = values?.amount;

    if (defaultValueFund) {
      if (Number(amount) > Number(defaultValueFund?.amount)) {
        NotificationManager.error(
          `Vui lòng nhấp số tiền ký quỹ nhở hơn ${formatNumber(
            defaultValueFund?.amount
          )}`
        );

        return null;
      }

      mutate({
        ...values,
        fund_id: defaultValueFund?.id,
      });

      return null;
    }
    NotificationManager.error("Nập cấp lên VIP để ký quỹ");
    return null;
  };

  const optionsData = useMemo(() => {
    if (!listFunds) return [];
    return listFunds?.map((item) => ({
      value: item?.id,
      label: item?.name,
      disabled: user?.level !== item?.level,
    }));
  }, [listFunds]);

  const renderBody = useMemo(
    () => (
      <form
        className="sbui-space-row sbui-space-x-5"
        style={{ alignItems: "flex-start", width: "100%" }}
        onSubmit={handleSubmit(onSave)}
      >
        <div
          className="sbui-space-col sbui-space-y-4"
          style={{ alignItems: "flex-start", width: "100%" }}
        >
          <h4 className="sbui-typography-title"> {i18next.t("add_fund")}</h4>
          <div className="flex flex-col w-full gap-4">
            <div>
              <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
                <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                  <div className="sbui-formlayout__label">
                    {i18next.t("amount")}
                  </div>
                </div>
                <div className="sbui-formlayout__content-container-horizontal">
                  <div className="sbui-input-container">
                    <input
                      placeholder={i18next.t("amount")}
                      type="number"
                      min={0}
                      max={1000000000000}
                      inputMode="decimal"
                      className="sbui-input sbui-input--medium"
                      {...register("amount")}
                    />
                    <div className="sbui-space-row sbui-space-x-1 sbui-input-actions-container">
                      <span className="sbui-btn-container">
                        <button
                          type="button"
                          className="sbui-btn sbui-btn-link sbui-btn--tiny sbui-btn--text-align-center"
                        >
                          <span>
                            {formatBalance(
                              game_wallet?.amount,
                              display_balance
                            )}
                          </span>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
              <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                <div className="sbui-formlayout__label">
                  {i18next.t("select_fund")}
                </div>
              </div>
              <div className="sbui-formlayout__content-container-horizontal">
                <SelectBox
                  options={optionsData}
                  defaultValue={{
                    value: defaultValueFund?.id,
                    label: defaultValueFund?.name,
                    disabled: false,
                  }}
                />
              </div>
            </div>
          </div>
          {!isValid && isSubmitted && (
            <p className="text-sm text-red-500">{i18next.t("note_12")}</p>
          )}

          <div
            className="sbui-space-row sbui-space-x-2"
            style={{ width: "100%", justifyContent: "flex-end" }}
          >
            <span className="sbui-btn-container">
              <button
                type="button"
                onClick={() => onClose()}
                className="sbui-btn sbui-btn-outline sbui-btn-container--shadow sbui-btn--tiny sbui-btn--text-align-center"
              >
                <span> {i18next.t("cancel")}</span>
              </button>
            </span>
            <span className="sbui-btn-container">
              <button
                type="submit"
                disabled={!isValid}
                className="sbui-btn sbui-btn-primary sbui-btn-container--shadow sbui-btn--tiny sbui-btn--text-align-center"
              >
                <span> {i18next.t("confirm")}</span>
              </button>
            </span>
          </div>
        </div>
      </form>
    ),
    [optionsData, isValid, isSubmitted]
  );

  if (type === "refund")
    return (
      <Portal>
        <div className="modal" style={{ display: open ? "block" : "none" }}>
          <div className="mask" onClick={onClose} />
          <div className="modal-content sbui-modal-content">
            <div
              className="sbui-space-row sbui-space-x-5"
              style={{ alignItems: "flex-start", width: "100%" }}
            >
              <div
                className="sbui-space-col sbui-space-y-4"
                style={{ alignItems: "flex-start", width: "100%" }}
              >
                <h4 className="sbui-typography-title">
                  {i18next.t("add_fund")}
                </h4>
                <span className="block">{i18next.t("note_18")}</span>
                <span className="block">
                  {i18next.t("note_9")}:{" "}
                  <a
                    className="text-green-600"
                    target="_blank"
                    href={general?.link_support}
                    rel="noreferrer"
                  >
                    {general?.link_support}
                  </a>
                </span>

                <div
                  className="sbui-space-row sbui-space-x-2"
                  style={{ width: "100%", justifyContent: "flex-end" }}
                >
                  <span className="sbui-btn-container">
                    <button
                      type="button"
                      onClick={() => onClose()}
                      className="sbui-btn sbui-btn-outline sbui-btn-container--shadow sbui-btn--tiny sbui-btn--text-align-center"
                    >
                      <span>{i18next.t("cancel")}</span>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Portal>
    );

  return (
    <Portal>
      <div className="modal" style={{ display: open ? "block" : "none" }}>
        <div className="mask" onClick={onClose} />
        <div className="modal-content sbui-modal-content">
          {user?.level ? (
            renderBody
          ) : (
            <div
              className="sbui-space-row sbui-space-x-5"
              style={{ alignItems: "flex-start", width: "100%" }}
            >
              <div
                className="sbui-space-col sbui-space-y-4"
                style={{ alignItems: "flex-start", width: "100%" }}
              >
                <h4 className="sbui-typography-title">
                  {i18next.t("add_fund")}
                </h4>
                <p>{i18next.t("note_17")}</p>
                <div
                  className="sbui-space-row sbui-space-x-2"
                  style={{ width: "100%", justifyContent: "flex-end" }}
                >
                  <span className="sbui-btn-container">
                    <button
                      type="button"
                      onClick={() => onClose()}
                      className="sbui-btn sbui-btn-outline sbui-btn-container--shadow sbui-btn--tiny sbui-btn--text-align-center"
                    >
                      <span>{i18next.t("cancel")}</span>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Portal>
  );
}
