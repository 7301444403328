import { useQuery } from "@tanstack/react-query";
import i18next from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { FundApi } from "states/api/fund";

export default function ListData({ data = [] }) {
  return (
    <div className="my-10">
      <div className="flex items-center justify-between">
        <h2 className="text-xl">Danh sách quỹ</h2>
      </div>
      <div className="sbui-loading">
        <div className="sbui-loading-content">
          <div className="flex flex-col mt-2">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full px-4 py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden rounded-lg shadow">
                  <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                    <thead className="bg-gray-50 dark:bg-gray-800">
                      <tr>
                        <th
                          scope="col"
                          className="p-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase md:px-6 md:py-3"
                        >
                          {i18next.t("funding_name")}
                        </th>
                        <th
                          scope="col"
                          className="p-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase md:px-6 md:py-3"
                        >
                          <span className="block text-center">
                            {" "}
                            {i18next.t("funding_required")}
                          </span>
                        </th>
                        <th
                          scope="col"
                          className="p-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase md:px-6 md:py-3"
                        >
                          <span className="block text-center">
                            {i18next.t("funding_profit")}
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-sm bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700 md:text-base">
                      {data?.map((item) => (
                        <tr
                          key={item?.id}
                          className="odd:bg-white dark:odd:bg-gray-800 even:bg-slate-50 dark:even:bg-gray-700"
                        >
                          <td className="p-2 text-gray-700 md:px-6 md:py-4 dark:text-gray-300">
                            <span className="text-xs sm:text-base">
                              {item?.name}
                            </span>
                          </td>
                          <td className="p-2 text-gray-700 md:px-6 md:py-4 dark:text-gray-300">
                            <span className="block text-xs text-center sm:text-base whitespace-nowrap">
                              {item?.description}
                            </span>
                          </td>
                          <td className="p-2 text-gray-700 md:px-6 md:py-4 dark:text-gray-300">
                            <span className="block text-xs text-center sm:text-base">
                              {item?.profit}%
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
