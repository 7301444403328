import React, { useEffect, useState } from "react";

import classNames from "classnames";
import { useStores } from "_common/hooks";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { NotificationManager } from "react-notifications";
import { PaymentApi } from "states/api/payment";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatBalance, formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";
import InputNumber from "_common/component/InputNumber";

import ListHistoryBalance from "./ListHistoryBalance";
import ModalBankDeposit from "./ModalBankDeposit";

const schema = yup
  .object({
    amount: yup.number().required("Bắt buộc điền"),
  })
  .required();

export default function Deposit() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [method, setMethod] = useState("game");

  const {
    authStore: { user, general, user_wallet, game_wallet, display_balance },
  } = useStores();

  const [openConfirm, setOpenConfirm] = useState(false);
  const [amount, setAmount] = useState();

  const {
    control,
    register,
    trigger,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { mutate: onSubmit, isLoading } = useMutation(
    (variables) =>
      PaymentApi.deposit({
        params: {
          ...variables,
          bank_method: method,
          token_id:
            method === "vnd" ? user_wallet?.tokenId : game_wallet?.tokenId,
          type: "deposit",
        },
      }),
    {
      onSuccess: (res) => {
        reset();
        setOpenConfirm(false);
        setAmount();
        queryClient.invalidateQueries(["get_profile"]);
        queryClient.invalidateQueries(["payment", "list"]);
        NotificationManager.success(res?.msg || "Rút tiền thành công!");
      },
      onError: (error) => {
        const errorMessage = error?.message ?? "Vui lòng thử lại!";
        NotificationManager.error(errorMessage);
      },
    }
  );

  const onSave = () => {
    if (isLoading) return null;

    if (amount < Number(general?.min_withdraw || 0))
      return NotificationManager.error(
        `Vui lòng nhập số tiền lớn hơn ${formatNumber(
          general?.min_withdraw
        )}đVND`
      );
    if (amount > Number(general?.max_withdraw || 0))
      return NotificationManager.error(
        `Vui lòng nhập số tiền nhỏ hơn ${formatNumber(
          general?.max_withdraw
        )}đVND`
      );
    onSubmit({ amount });
    return null;
  };

  return (
    <div id="withdraw" className="container profileContent">
      <div className="row">
        <div className="container px-4 mx-auto my-10 col-lg-7 col-sm-9 col-11">
          <div className="sbui-card w-full h-full">
            <form className="sbui-card-content" onSubmit={handleSubmit(onSave)}>
              <div className="flex flex-col w-full gap-4">
                <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
                  <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-vertical">
                    <div className="sbui-formlayout__label">
                      {i18next.t("Phương thức nạp tiền")}
                    </div>
                  </div>
                  <div className="sbui-formlayout__content-container-vertical">
                    <div className="sbui-select-container">
                      <select className="sbui-select sbui-select--medium">
                        <option value="970425">CHUYỂN KHOẢN</option>
                      </select>
                      <span className="sbui-select-chevron-container">
                        <svg
                          className="sbui-select-chevron"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
                  <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-vertical">
                    <div className="sbui-formlayout__label">
                      {i18next.t("Mục tiêu lưu trữ")}
                    </div>
                  </div>
                  <div className="sbui-formlayout__content-container-vertical">
                    <div className="sbui-select-container">
                      <select className="sbui-select sbui-select--medium">
                        <option value="970425">VÍ ĐIỆN TỬ</option>
                      </select>
                      <span className="sbui-select-chevron-container">
                        <svg
                          className="sbui-select-chevron"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="boxAddress">
                    <div className="form-group position-relative">
                      <h4 className="colorSecondary2">
                        Nhập số tiền bạn muốn nạp
                      </h4>
                      <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <InputNumber
                            min={0}
                            placeholder={`Tối thiểu ${formatNumber(
                              general?.min_deposit
                            )} VND`}
                            onBlur={onBlur}
                            value={value}
                            className="sbui-input"
                            onChangeText={(e) => {
                              onChange(e);
                              setAmount(e);
                            }}
                          />
                        )}
                        name="amount"
                      />
                    </div>
                  </div>
                </div>
                <span className="sbui-btn-container sbui-btn--w-full">
                  <button
                    type="button"
                    className="sbui-btn sbui-btn-primary sbui-btn--w-full sbui-btn-container--shadow sbui-btn--large sbui-btn--text-align-center"
                    onClick={() => setOpenConfirm(true)}
                  >
                    <span>{i18next.t("confirm")}</span>
                  </button>
                </span>
              </div>
            </form>
          </div>
          <div className="mt-8">
            <h4 className="font-24  transaction-history-title">
              <b>Lịch sử rút tiền</b>
            </h4>
            <ListHistoryBalance type="deposit" />
          </div>
        </div>
      </div>
      {openConfirm && (
        <ModalBankDeposit
          open={openConfirm}
          amount={amount}
          onClose={() => setOpenConfirm(false)}
          onFinish={onSave}
        />
      )}
    </div>
  );
}
