import { useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import ModalBlock from "pages/Home/ModalBlock";
import ModalBonus from "pages/Home/ModalBonus";
import React, { useEffect, useState } from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import { useNotify } from "_common/component/NotifyProvider";
import { useStores } from "_common/hooks";

import HeaderDesktop from "../Header/HeaderDesktop";
import DesktopSideBar from "../SideBar/DesktopSideBar";

const MobileLayout = observer(() => {
  const queryClient = useQueryClient();
  const { notify, setNotify } = useNotify();
  const {
    authStore: { user },
  } = useStores();
  const [openModalNotify, setModalNotify] = useState(false);
  const [openModalBlock, setModalBlock] = useState(false);
  const [dataNotice, setDataNotice] = useState();
  const [is_open, setMenuOpen] = useState();

  useEffect(() => {
    if (notify?.data?.data?.user_id === user?.id) {
      queryClient.invalidateQueries(["get_profile"]);
      setNotify();
    }
  }, [notify, user]);

  useEffect(() => {
    if (
      notify?.data?.type === "send_notice" &&
      notify?.data?.data?.user_id === user?.id
    ) {
      setDataNotice(notify?.data?.data);
      if (typeof poof === "function") {
        // eslint-disable-next-line no-undef
        poof();
      }
      setModalNotify(true);
      setNotify();
    }
  }, [notify, user]);

  useEffect(() => {
    if (!user?.status) {
      setModalBlock(true);
    } else {
      setModalBlock(false);
    }
  }, [user?.status]);

  return (
    <div className="tw-dark">
      <div className="desktop-layout font-['Roboto'] dark:text-gray-300 text-gray-900 w-full h-full">
        <div className="flex w-full h-full">
          <DesktopSideBar is_open={is_open} setMenuOpen={setMenuOpen} />
          <div
            className={classNames(
              "flex flex-col w-full h-screen max-h-full min-h-full lg:ml-24 transition-all desktop-body",
              {
                "ml-0": !is_open,
                "ml-24": is_open,
              }
            )}
          >
            <HeaderDesktop is_open={is_open} setMenuOpen={setMenuOpen} />
            <Outlet />
          </div>
        </div>
      </div>
      {openModalNotify && (
        <ModalBonus
          open={openModalNotify}
          onClose={() => setModalNotify(false)}
          data={dataNotice}
        />
      )}
      {openModalBlock && (
        <ModalBlock
          open={openModalBlock}
          onClose={() => setModalBlock(false)}
        />
      )}
    </div>
  );
});

export default MobileLayout;
