import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "_common/hooks";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import i18next from "i18next";

import depositIcon from "assets/icons/icon-deposit.svg";
import withdrawIcon from "assets/icons/icon-withdraw.svg";
import { formatBalance, formatNumber } from "_common/utils/formatValue";

export default function General() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { display_balance, user_wallet, game_wallet, clear },
  } = useStores();

  return (
    <div className="boxCoin bgSecondary4 borderRounded borderSecondary2">
      <div className="boxCoin-body borderBtSecondary2 align-content-center d-flex p-3">
        <div className="rightBox my-auto">
          <div className="flex justify-center items-center">
            <h5 className="font-bold color-white coin-value-1">0 VND</h5>
          </div>
        </div>
      </div>
      <div className="boxCoinFooter p-3 flex items-center">
        <div className="text-center flex items-center justify-center gap-4">
          <img src={depositIcon} alt="" className="mx-2" />
          <Link
            to="/board/deposit"
            className="text-white font-12 decoration-none"
          >
            Nạp Tiền
          </Link>
        </div>
        <div className="text-center flex items-center justify-center gap-4">
          <img src={withdrawIcon} alt="" className="mx-2" />
          <Link
            to="/board/withdraw"
            className="text-white font-12 decoration-none"
          >
            Rút Tiền
          </Link>
        </div>
      </div>
    </div>
  );
}
