import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useQuery } from "@tanstack/react-query";
import { FundApi } from "states/api/fund";
import { useStores } from "_common/hooks";
import { formatBalance, formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";
import ListData from "./ListData";
import ModalAddFund from "./ModalAddFund";

const FundPage = observer(() => {
  const {
    authStore: { user, game_wallet, display_balance },
  } = useStores();

  const [openModal, setOpenModal] = useState(false);
  const [type, setType] = useState("fund");

  const { data, refetch } = useQuery(
    ["funds", "list"],
    () =>
      FundApi.getList({
        params: {
          page: 1,
          limit: 50,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  const handleOpenModal = (value) => {
    setOpenModal(true);
    setType(value);
  };

  return (
    <div className="container  px-4 mx-auto my-10">
      <div className="flex flex-col w-full gap-10 lg:flex-row">
        <div className="flex flex-1 w-full">
          <div className="sbui-card w-full h-full">
            <div className="sbui-card-content">
              <div className="flex justify-between w-full">
                <div className="flex flex-col items-center justify-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="sbui-icon "
                  >
                    <line x1="12" y1="1" x2="12" y2="23" />
                    <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6" />
                  </svg>
                  <h3 className="text-gray-400 dark:text-gray-400">
                    {i18next.t("asset_value")}(VND)
                  </h3>
                  <span className="text-xl font-bold text-green-700">
                    {formatBalance(game_wallet?.amount, display_balance)} VND
                  </span>
                  <div className="flex items-center justify-center gap-2">
                    <span className="sbui-btn-container">
                      <button
                        type="button"
                        onClick={() => handleOpenModal("refund")}
                        className="sbui-btn sbui-btn-outline sbui-btn-container--shadow sbui-btn--tiny !text-green-600 sbui-btn--text-align-center"
                      >
                        <span>{i18next.t("pay")}</span>
                      </button>
                    </span>
                    <span className="sbui-btn-container">
                      <button
                        type="button"
                        className="sbui-btn sbui-btn-outline sbui-btn-container--shadow sbui-btn--tiny !text-green-600 sbui-btn--text-align-center"
                        onClick={() => handleOpenModal("fund")}
                      >
                        <span>{i18next.t("send")}</span>
                      </button>
                    </span>
                  </div>
                </div>
                <div className="flex flex-col items-center gap-2">
                  <div className="flex flex-col items-center">
                    <h4 className="text-gray-400 dark:text-gray-600">
                      {i18next.t("total_funding")}
                    </h4>
                    <span className="text-xl font-bold">
                      {formatNumber(user?.fund?.total)} VND
                    </span>
                  </div>
                  <div className="flex flex-col items-center">
                    <h4 className="text-gray-400 dark:text-gray-600">
                      {i18next.t("funding_profit")}
                    </h4>
                    <span className="text-xl font-bold text-green-600">
                      {formatNumber(user?.fund?.profit)}%
                    </span>
                  </div>
                  <div className="flex flex-col items-center">
                    <h4 className="text-gray-400 dark:text-gray-600">
                      {i18next.t("membership")}
                    </h4>
                    <span className="text-green-600">
                      {user?.level ? `Vip ${user?.level}` : "Tài khoản thường"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-1 w-full">
          <div className="sbui-card">
            <div className="sbui-card-content">
              <div className="flex flex-col gap-2 text-gray-400">
                <span>{i18next.t("note_13")}</span>
                <span>{i18next.t("note_14")}</span>
                <span>{i18next.t("note_15")}</span>
                <span>{i18next.t("note_16")}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ListData data={data?.data || []} />
      {openModal && (
        <ModalAddFund
          type={type}
          listFunds={data?.data || []}
          open={openModal}
          onClose={() => setOpenModal(false)}
        />
      )}
    </div>
  );
});

export default FundPage;
