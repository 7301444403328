import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { useStores } from "_common/hooks";
import classNames from "classnames";
import InputPassword from "_common/component/InputPassword";
import i18next from "i18next";

const schema = yup
  .object({
    phone: yup.string().required("Bắt buộc điền"),
    full_name: yup.string().required("Bắt buộc điền"),
    username: yup.string().required("Vui lòng nhập tên người dùng"),
    password: yup.string().required("Vui lòng nhập mật khẩu"),
    ref_no: yup.string().required("Vui lòng nhập mã giới thiệu"),
  })
  .required();

export default function RegisterForm({ setTypeOpen }) {
  const { authStore } = useStores();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const { mutate, isLoading } = useMutation(
    (variables) => AuthApi.register(variables),
    {
      onSuccess: (res) => {
        authStore.updateToken(res?.data);
        authStore.updateUser(res?.data);
        setTimeout(() => {
          navigate("/board/wallet");
        }, 200);
      },
      onError: (error) => {
        const errorMessage = error?.message ?? "Vui lòng thử lại!";
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleRegister = (values) => {
    if (isLoading) return;
    console.log(values);
    mutate(values);
  };

  return (
    <form id="auth-sign-up" onSubmit={handleSubmit(handleRegister)}>
      <div className="sbui-space-col sbui-space-y-6">
        <div className="sbui-space-col sbui-space-y-3">
          <div>
            <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
              <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                <div className="sbui-formlayout__label">
                  {i18next.t("Họ tên thật")} *
                </div>
              </div>
              <div className="sbui-formlayout__content-container-horizontal">
                <div className="sbui-input-container">
                  <input
                    type="text"
                    placeholder={i18next.t("Họ tên thật")}
                    className="sbui-input sbui-input--medium"
                    {...register("full_name")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
              <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                <div className="sbui-formlayout__label">
                  {i18next.t("Số điện thoại")} *
                </div>
              </div>
              <div className="sbui-formlayout__content-container-horizontal">
                <div className="sbui-input-container">
                  <input
                    type="text"
                    placeholder={i18next.t("Vui lòng nhập SDT liên hệ")}
                    className="sbui-input sbui-input--medium"
                    {...register("phone")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
              <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                <div className="sbui-formlayout__label">
                  {i18next.t("username_login")} *
                </div>
              </div>
              <div className="sbui-formlayout__content-container-horizontal">
                <div className="sbui-input-container">
                  <input
                    type="text"
                    placeholder={i18next.t("username_login")}
                    className="sbui-input sbui-input--medium"
                    {...register("username")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
              <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                <div className="sbui-formlayout__label">
                  {i18next.t("password")} *
                </div>
              </div>
              <div className="sbui-formlayout__content-container-horizontal">
                <div className="sbui-input-container">
                  <input
                    autoComplete="current-password"
                    placeholder={i18next.t("Điền mật khẩu...")}
                    type="password"
                    className="sbui-input sbui-input--medium"
                    {...register("password")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
              <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                <div className="sbui-formlayout__label">
                  {i18next.t("Nhập Lại Mật khẩu")} *
                </div>
              </div>
              <div className="sbui-formlayout__content-container-horizontal">
                <div className="sbui-input-container">
                  <input
                    autoComplete="current-password"
                    placeholder={i18next.t("Điền mật khẩu...")}
                    type="password"
                    className="sbui-input sbui-input--medium"
                    {...register("password")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
              <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                <div className="sbui-formlayout__label">
                  {i18next.t("Số điện thoại Zalo")}
                </div>
              </div>
              <div className="sbui-formlayout__content-container-horizontal">
                <div className="sbui-input-container">
                  <input
                    type="text"
                    placeholder={i18next.t("Vui lòng nhập SDT Zalo")}
                    className="sbui-input sbui-input--medium"
                    {...register("phone_zalo")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
              <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                <div className="sbui-formlayout__label">
                  {i18next.t("ref_text")}
                </div>
              </div>
              <div className="sbui-formlayout__content-container-horizontal">
                <div className="sbui-input-container">
                  <input
                    type="text"
                    placeholder={i18next.t("ref_text")}
                    className="sbui-input sbui-input--medium"
                    {...register("ref_no")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="sbui-space-row sbui-space-x-2"
            style={{ justifyContent: "space-between" }}
          >
            <div className="sbui-checkbox-container sbui-checkbox-container--medium">
              <input
                id="remember_me"
                name="remember_me"
                type="checkbox"
                className="sbui-checkbox"
                value="remember_me"
              />
              <div className="sbui-checkbox__label-container">
                <div
                  className="sbui-checkbox__label-container__label"
                  htmlFor="remember_me"
                >
                  <span className="sbui-checkbox__label-container__label__span">
                    Xác nhận rằng tôi trên 18 tuổi và mọi hoạt động trên trang
                    web này không vi phạm pháp luật do quốc gia nơi tôi sinh
                    sống. Tôi cũng chấp nhận tất cả các quy tắc và quy định liên
                    quan và tuyên bố về quyền riêng tư trong ứng dụng này.
                  </span>
                </div>
              </div>
            </div>
            {/* <a
              className=" sbui-typography-link inline-block px-4 py-2 border rounded-lg dark:border-slate-700 border-slate-400"
              href="#auth-forgot-password"
              target="_blank"
              rel="noopener noreferrer"
            >
              Quên mật khẩu?
            </a> */}
          </div>
        </div>
        <div className="sbui-space-col sbui-space-y-6">
          <span className="sbui-btn-container sbui-btn--w-full">
            <button
              className="sbui-btn sbui-btn-primary sbui-btn--w-full sbui-btn-container--shadow sbui-btn--2xl sbui-btn--text-align-center"
              type="submit"
              disabled={!isValid || isLoading}
            >
              <span>{i18next.t("register")}</span>
            </button>
          </span>
        </div>
        <div
          className="sbui-space-col sbui-space-y-2"
          style={{ textAlign: "center" }}
        >
          <p>
            Có tài khoản {authStore?.general?.name_website}?
            <span
              onClick={() => setTypeOpen("login")}
              className=" sbui-typography-link inline-block px-2 py-2 text-primary"
            >
              {i18next.t("note_20")}
            </span>
          </p>
        </div>
      </div>
    </form>
  );
}
