import React, { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { NotificationManager } from "react-notifications";

import { AuthApi } from "states/api";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useStores } from "_common/hooks";

import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";
import InputPassword from "_common/component/InputPassword";
import i18next from "i18next";

const schema = yup
  .object({
    username: yup.string().required("Bắt buộc điền"),
    password: yup.string().required("Bắt buộc điền"),
  })
  .required();

export default function LoginForm({ setTypeOpen }) {
  const { authStore } = useStores();
  const navigate = useNavigate();
  const { mutate, isLoading } = useMutation(
    (variables) => AuthApi.login(variables),
    {
      onSuccess: (res) => {
        authStore.updateUser(res?.data);
        setTimeout(() => {
          navigate("/board/wallet");
        }, 200);
      },
      onError: (error) => {
        const errorMessage = error?.message ?? "Vui lòng thử lại!";
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleLogin = (values) => {
    if (isLoading) return;
    mutate(values);
  };

  return (
    <form className="auth-sign-in" onSubmit={handleSubmit(handleLogin)}>
      <div className="sbui-space-col sbui-space-y-6">
        <div className="sbui-space-col sbui-space-y-3">
          <div>
            <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
              <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                <div className="sbui-formlayout__label">
                  {i18next.t("username_login")}*
                </div>
              </div>
              <div className="sbui-formlayout__content-container-horizontal">
                <div className="sbui-input-container">
                  <input
                    type="text"
                    placeholder={i18next.t("username_login")}
                    className="sbui-input sbui-input--large"
                    {...register("username")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
              <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                <div className="sbui-formlayout__label">
                  {i18next.t("password")}*
                </div>
              </div>
              <div className="sbui-formlayout__content-container-horizontal">
                <div className="sbui-input-container">
                  <input
                    autoComplete="current-password"
                    placeholder={i18next.t("password")}
                    type="password"
                    className="sbui-input sbui-input--large"
                    {...register("password")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sbui-space-col sbui-space-y-6">
          <span className="sbui-btn-container sbui-btn--w-full">
            <button
              className="sbui-btn sbui-btn-primary sbui-btn--w-full sbui-btn-container--shadow sbui-btn--2xl sbui-btn--text-align-center"
              type="submit"
            >
              <span> {i18next.t("login")}</span>
            </button>
          </span>
        </div>
        <div
          className="sbui-space-col sbui-space-y-2"
          style={{ textAlign: "center" }}
        >
          <p>
            Cần tài khoản {authStore.general?.name_website}?
            <span
              onClick={() => setTypeOpen("register")}
              className=" sbui-typography-link inline-block px-2 py-2 mx-auto text-primary"
            >
              {i18next.t("register")}
            </span>
          </p>
        </div>
      </div>
    </form>
  );
}
