import React, { useEffect, useState } from "react";
import { useStores } from "_common/hooks";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { NotificationManager } from "react-notifications";
import i18next from "i18next";

export default function MemberLevel() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, user_wallet, clear },
  } = useStores();

  return (
    <div className="sbui-card my-10">
      <div className="sbui-card-content">
        <div className="flex border-b border-[#2a2a2a] -mx-8 px-10 md:px-5 -mt-8 py-8 mb-8">
          <div className="flex items-end gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="sbui-icon w-8 h-8 text-green-500"
            >
              <path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z" />
              <line x1="7" y1="7" x2="7.01" y2="7" />
            </svg>
            <span className="text-lg">{i18next.t("membership")}</span>
          </div>
        </div>
        <div className="flex flex-col justify-between gap-4 mb-5 text-base md:gap-10 md:flex-row">
          <div className="flex flex-col w-full text-gray-800 dark:text-gray-400">
            <span className="text-xl text-green-600">
              {i18next.t("normal_member")}
            </span>
            <span>{i18next.t("note_1")}</span>
            <span>{i18next.t("note_2")}</span>
            <span>{i18next.t("note_3")}</span>
          </div>
          <div className="flex flex-col w-full text-gray-800 dark:text-gray-400">
            <span className="text-xl text-green-600">
              {i18next.t("vip_member")}
            </span>
            <span> {i18next.t("note_8")}</span>
            <span>{i18next.t("note_4")}</span>
            <span>{i18next.t("note_5")}</span>
            <span>{i18next.t("note_6")}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
