import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useQuery } from "@tanstack/react-query";
import { GameApi } from "states/api/game";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import { get } from "lodash";
import { useStores } from "_common/hooks";
import GameBoard from "./GameBoard";
import ChartLine from "./ChartLine";
import HistoryData from "./HistoryData";


const TradePage = observer(() => {
  const {
    authStore: { games },
  } = useStores();
  const { isMobile } = useMediaQuery();

  const [game_id, setGameId] = useState(games[0]?.code);

  const { data, refetch } = useQuery(
    ["game", "list"],
    () =>
      GameApi.getList({
        params: {
          status: "finish",
          page: 1,
          limit: 100,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  const listData = get(data, "data", [])?.reverse() || [];

  return (
    <div className="flex flex-col flex-wrap flex-1 lg:flex-row" id="game-container">
      <div className="flex flex-1 overflow-hidden">
        <div className="relative w-full h-full">
          <div className="relative w-full h-full">
            <div className="w-full h-full">
              <ChartLine game_id={game_id} />
              {
                !isMobile && (
                  <div className="history-trade">
                  <HistoryData game_id={game_id} data={listData?.slice(0, 20)}/>
                  <HistoryData game_id={game_id} data={listData?.slice(20, 40)}/>
                  <HistoryData game_id={game_id} data={listData?.slice(40, 60)}/>
                  <HistoryData game_id={game_id} data={listData?.slice(60, 80)}/>
                  <HistoryData game_id={game_id} data={listData?.slice(80, 100)}/>
                </div>
                )
              }
          
            </div>
          </div>
        </div>
      </div>
      <GameBoard game_id={game_id} />
    </div>
  );
});

export default TradePage;
