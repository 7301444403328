import React, { useEffect, useState } from "react";
import { Portal } from "react-portal";
import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";

function ModalBonus({ open, data, onClose }) {
  const {
    authStore: { general },
  } = useStores();

  return (
    <Portal>
      <div
        className="modal modal-bet-game modal-gift"
        style={{ display: open ? "block" : "none" }}
      >
        <div className="mask" />
        <div className="modal-content">
          <div className="classic-bet-now">
            <div className="title">
              <h5>Xin chúc mừng</h5>
            </div>
            <div className="content">
              <p>
                <b>
                  Chúc mừng bạn nhận được {` `}
                  {formatNumber(data?.amount_payback)} VND
                </b>
              </p>
            </div>
            <div className="submit">
              <button
                type="button"
                className="btn btn-submit"
                style={{ width: 200 }}
                onClick={() => {
                  window.location.reload();
                }}
              >
                Xác nhận
              </button>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
}

export default ModalBonus;
