import React, { useEffect } from "react";

import { useStores } from "_common/hooks";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useForm } from "react-hook-form";
import { ProfileApi } from "states/api";
import { NotificationManager } from "react-notifications";
import { handleCopyLink } from "_common/utils/helper";
import { get } from "lodash";
import i18next from "i18next";

const schema = yup
  .object({
    full_name: yup.string().required("Bắt buộc điền"),
    first_name: yup.string(),
    last_name: yup.string(),
  })
  .required();

export default function UpdateUser() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, user_wallet, clear },
  } = useStores();

  const { mutate, isLoading } = useMutation(
    (variables) => ProfileApi.update({ params: variables }),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["get_profile"]);
        NotificationManager.success(res?.msg || "Thao tác thành công");
      },
      onError: (error) => {
        const errorMessage = error?.message ?? "Vui lòng thử lại!";
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSave = (values) => {
    if (isLoading) return;
    trigger();
    const formValue = getValues();
    const isValid = schema.isValidSync(formValue);
    if (isValid) {
      mutate(formValue);
    }
  };

  useEffect(() => {
    if (!user) return;
    setValue("email", user?.email);
    setValue("full_name", user?.full_name);
    setValue("first_name", user?.first_name);
    setValue("last_name", user?.last_name);
  }, [user]);

  return (
    <div className="sbui-card">
      <div className="sbui-card-content">
        <form onSubmit={handleSubmit(onSave)}>
          <div className="flex items-center justify-between gap-1 mb-5 md:gap-10">
            <div className="flex flex-col w-full max-w-[120px] md:max-w-none">
              <div className="flex flex-col items-center justify-between gap-4 mb-5 md:gap-10 md:flex-row">
                <div className="flex flex-col w-full">
                  <div>
                    <div className="sbui-formlayout sbui-formlayout--large sbui-formlayout--responsive">
                      <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                        <div className="sbui-formlayout__label">Tài khoản</div>
                      </div>
                      <div className="sbui-formlayout__content-container-horizontal">
                        <div className="sbui-input-container">
                          <input
                            placeholder="Tài khoản"
                            type="text"
                            className="sbui-input sbui-input--large"
                            value={user?.username}
                            readOnly
                          />
                          <div className="sbui-space-row sbui-space-x-1 sbui-input-actions-container">
                            <span className="sbui-btn-container">
                              <button
                                type="button"
                                onClick={() => handleCopyLink(user?.username)}
                                className="sbui-btn sbui-btn-primary sbui-btn-container--shadow sbui-btn--tiny sbui-btn--text-align-center"
                              >
                                <span> {i18next.t("copy")}</span>
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-full">
                  <div>
                    <div className="sbui-formlayout sbui-formlayout--large sbui-formlayout--responsive">
                      <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                        <div className="sbui-formlayout__label">
                          {i18next.t("full_name")}
                        </div>
                      </div>
                      <div className="sbui-formlayout__content-container-horizontal">
                        <div className="sbui-input-container">
                          <input
                            placeholder={i18next.t("full_name")}
                            type="text"
                            className="sbui-input sbui-input--large"
                            {...register("full_name")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-center justify-between gap-4 mb-5 md:gap-10 md:flex-row">
                <div className="flex flex-col w-full">
                  <div>
                    <div className="sbui-formlayout sbui-formlayout--large sbui-formlayout--responsive">
                      <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                        <div className="sbui-formlayout__label">
                          {i18next.t("last_name")}
                        </div>
                      </div>
                      <div className="sbui-formlayout__content-container-horizontal">
                        <div className="sbui-input-container">
                          <input
                            placeholder={i18next.t("last_name")}
                            type="text"
                            className="sbui-input sbui-input--large"
                            {...register("last_name")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-full">
                  <div>
                    <div className="sbui-formlayout sbui-formlayout--large sbui-formlayout--responsive">
                      <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                        <div className="sbui-formlayout__label">
                          {i18next.t("first_name")}
                        </div>
                      </div>
                      <div className="sbui-formlayout__content-container-horizontal">
                        <div className="sbui-input-container">
                          <input
                            placeholder={i18next.t("first_name")}
                            type="text"
                            className="sbui-input sbui-input--large"
                            {...register("first_name")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full">
              <div className="flex flex-col gap-6">
                <div className="flex flex-col gap-2">
                  <h2 className="flex items-center gap-2 text-gray-800 dark:text-gray-400">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="sbui-icon "
                    >
                      <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
                      <circle cx="8.5" cy="7" r="4" />
                      <line x1="20" y1="8" x2="20" y2="14" />
                      <line x1="23" y1="11" x2="17" y2="11" />
                    </svg>
                    <span>{i18next.t("member_id")}</span>
                  </h2>
                  <h4 className="font-bold text-green-500 md:text-xl">
                    <div className="flex items-center">
                      <span>{user?.ref_no}</span>
                      <span className="sbui-btn-container">
                        <button
                          type="button"
                          aria-label="web"
                          onClick={() => handleCopyLink(user?.ref_no)}
                          className="sbui-btn sbui-btn-text sbui-btn--tiny sbui-btn--text-align-center"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="sbui-icon "
                          >
                            <rect
                              x="9"
                              y="9"
                              width="13"
                              height="13"
                              rx="2"
                              ry="2"
                            />
                            <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
                          </svg>
                        </button>
                      </span>
                    </div>
                  </h4>
                </div>
                <div className="flex flex-col gap-2">
                  <h2 className="flex items-center gap-2 text-gray-800 dark:text-gray-400">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="sbui-icon "
                    >
                      <path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z" />
                      <line x1="7" y1="7" x2="7.01" y2="7" />
                    </svg>
                    {i18next.t("membership")}
                  </h2>
                  <h4 className="font-bold text-green-500 md:text-xl">
                    {user?.level
                      ? `Thành viên Vip/Vip ${user?.level}`
                      : "Tài khoản thường"}
                  </h4>
                </div>
                <div className="flex items-center justify-between w-full gap-1">
                  <span className="text-gray-800 dark:text-gray-400">
                    <span className="text-xs md:text-base">
                      {i18next.t("activity_point")}:{" "}
                    </span>
                    <span className="font-bold text-green-500">
                      {get(user, "active_point", 100)}/100
                    </span>
                  </span>
                  <span className="text-gray-800 dark:text-gray-400">
                    <span className="text-xs md:text-base">
                      {i18next.t("credit_point")}:{" "}
                    </span>
                    <span className="font-bold text-green-500">
                      {get(user, "payment_point", 100)}/100
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center justify-between gap-4 mb-5 md:gap-10 md:flex-row">
            <div className="flex flex-col w-full">
              <span className="sbui-btn-container sbui-btn--w-full">
                <button
                  type="submit"
                  className="sbui-btn sbui-btn-primary sbui-btn--w-full sbui-btn-container--shadow sbui-btn--large sbui-btn--text-align-center"
                >
                  <span>{i18next.t("update_profile")}</span>
                </button>
              </span>
            </div>
            <div className="flex items-center justify-between w-full" />
          </div>
        </form>
      </div>
    </div>
  );
}
